<template>
  <default-dialog
    :show-dialog="show"
    width="716px"
    :title="$t('page.mission.import')"
    :next-button-text="$t('button.import')"
    :center="true"
    footer-align="center"
    @close="close"
    @next="importMission"
  >
    <template slot="main">
      <div class="mission-export">
        <label
          v-if="!preSelectMission"
          class="export-mission-label"
        >{{ $t('page.mission.filename') }}</label>
        <el-input
          v-model="fileName"
          class="mission-export-name"
        />

        <!-- <el-button type="mini"  class="upload"
                   @click="clickUpload()"
        ></el-button> -->

        <input
          ref="hiddenUpload"
          type="file"
          accept="*.circ"
          style="display:none"
          @change="fileSelected"
        >
      </div>
      <div
        class="mission-selection"
        @drop="drop"
        @dragover="allowDrop"
      >
        <div :class="{'missions': true, 'preSelect': (preSelectMission != null)}">
          <div
            v-for="(mission, index) in missions"
            :key="`drone-${index}`"
            :class="{
              'mission': true,
              'activate': (select.mission == mission.id),
            }"
            :title="mission.name"
            @click="selectMission(mission)"
          >
            <google-maps-mission-thumbnail
              class="thumbnail"
              :mission="mission"
            />
            <span
              class="name overflow-cut"
              :title="mission.name"
            >{{ mission.name }}</span>
            <span class="period">{{ FormatMissionPeriod(mission.period) }}</span>
            <img
              class="select"
              src="/asserts/icon/loadmission-selected-icon.svg"
            >
          </div>
        </div>
        <div
          v-if="missions.length==0"
          class="no-mission__content"
        >
          <h3>{{ $t('page.mission.dragndrop') }}</h3>
          <p>{{ $t('page.mission.or') }}</p>
          <el-button
            type="primary"
            class="main-actions"
            @click="clickUpload()"
          >
            {{ $t('page.mission.filepath') }}
          </el-button>
        </div>
      </div>
      <hr>
    </template>
  </default-dialog>
</template>

<script>
import { FormatMissionPeriod } from '@/utils/mission.js';
import { mapGetters } from 'vuex';
import { logger } from '@/logger/index';
import DefaultDialog from '@/components/DefaultDialog.vue';
import DroneApi from '@/services/api/domain/group/drone';
import MissionApi from '@/services/api/domain/group/mission';
import GoogleMapsMissionThumbnail from '@/components/GoogleMaps/MissionThumbnail.vue';
import fileDownload from 'js-file-download';

export default {
  name: 'AssignMissionDialog',
  components: {
    DefaultDialog,
    GoogleMapsMissionThumbnail,
  },
  data() {
    return {
      googleMapKey: this.$config.googleMapKey,
      periodUnit: this.$store.state.mission.periodUnit,
      show: false,
      drones: [],
      missions: [],
      select: {
        drone: null,
        mission: null,
      },
      fileName: null,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
    }),
    missionLoaded() {
      return this.missios.length > 0;
    },
  },
  watch: {
    preSelectMission(now) {
      // console.log("pre selection change", now, old);
      this.missions = [now];
      this.select.mission = this.missions[0].id;
    },
  },
  mounted() {
    // this.$refs.hiddenUpload.value='';
  },
  props: {
    preSelectMission: {
      required: false,
      type: Object,
      default: null,
    },
    customAction: {
      required: false,
      type: Boolean,
      default: false,
    },
    requireDrone: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickUpload() {
      this.$refs.hiddenUpload.value = '';
      this.$refs.hiddenUpload.click();
    },
    open() {
      this.missions.splice(0, this.missions.length);
      logger.debug('open');
      this.show = true;
    },
    close() {
      this.show = false;
      this.select.drone = null;
      this.select.mission = null;
    },
    selectMission(mission) {
      this.select.mission = mission.id;
    },
    fileSelected(event) {
      this.missions.splice(0, this.missions.length);

      const { files } = event.target; // 取得File物件

      [].forEach.call(files, this.fileReader);
    },
    fileReader(file) {
      if (file.name.indexOf('.circ') > 0) {
        const reader = new FileReader(); // 建立FileReader 監聽 Load 事件
        reader.addEventListener('load', this.createImage);
        reader.readAsText(file);
      } else {
        this.$showFail();
      }
    },
    createImage(event) {
      const file = event.target;
      const json = JSON.parse(file.result);
      this.missions.push(json);
      this.fileName = json.name;
    },
    async importMission() {
      logger.debug('importMission');
      if (this.missions.length > 0) {
        if (this.missions[0].group_id !== this.group.id) {
          this.$showFail();
          return;
        }
        try {
          if (this.fileName && this.fileName.trim().length > 0) {
            this.missions[0].name = this.fileName.trim();
            const { data } = await MissionApi.create(this.group.id, this.missions[0]);
            this.$showSuccess(data.msg);
          } else {
            const { data } = await MissionApi.create(this.group.id, this.missions[0]);
            this.$showSuccess(data.msg);
          }
          this.close();
          logger.debug('123');
          this.$router.go();
        } catch (error) {
          this.$showFail(error);
        }
      } else {
        this.$showFail();
      }
    },
    FormatMissionPeriod,
    allowDrop(event) {
      logger.debug('allowDrop');
      event.preventDefault();
    },
    drop(event) {
      this.missions.splice(0, this.missions.length);
      logger.debug('drop');
      this.fileReader(event.dataTransfer.files[0]);
      // this.$emit("drop", {index});
      event.preventDefault();
    },
  },
};
</script>
<style lang="scss">
.mission-export{
  height: 42px;
  width: 716px;
  margin-left: -38px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;

  .export-mission-label {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 15px;
  }

  .upload{
     outline:0;
    width: 30px;
    height: 26px;
    // vertical-align: middle;
    background-image: url('/asserts/icon/export-mission-target-icon.svg')
  }

  .mission-export-name{
    width:184px;
    height: 26px;
    .el-input__inner{
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 26px;
      line-height: 26px;
      outline: 0;
      padding: 0 15px;
      -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
      transition: border-color .2s cubic-bezier(.645,.045,.355,1);
      width: 100%;
    }
  }

  .el-button--mini {
      min-width: 30px;
      height: 26px;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      border: solid 1px #dbdbdb;
    }
}

.el-dialog {
  hr {
    color: #c5c5c5;
    background-color: #c5c5c5;
    height: 1px;
    border: 0;
    margin-left: -38px;
    margin-right: -38px;
  }
}
.mission-selection {

  border-style: dashed;
  border-color: #e1e1e1;
  border-radius: 13px;
  height: 461px;
  // overflow: auto;
  .missions {
    margin-top: 21px;
    margin-left: 8px;
    // margin-right: 40px;
    .mission {
      cursor: pointer;
      float: left;
      width: 176px;
      height: 172px;
      padding-top:12px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px transparent;

      position: relative;
      margin-left: 8px;
      span {
        display: block;
        margin-top: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .thumbnail {
        width: 156px;
        height: 104px;
      }
      img.select {
        display: none;
      }
    }

    .mission:first-child:last-child {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .missions:not(.preSelect) {
    .mission:hover {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
    }

    .mission.activate {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
      img.select {
        display: block;
        position: absolute;
        top: -12px;
        right: -12px;

      }
    }
  }

  .no-mission__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    text-align: center;
    h3 {
      color: #a5a5a5;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 22px;
    }

    p {
      color: rgba(165, 165, 165, 0.9);
    }

    button {
      margin-top: 36px;
      margin-bottom: 66px;
    }
  }

}
.drone-selection {
  line-height: 100px;
  height: 100px;
}
</style>
