<template>
  <admin-fillall-layout>
    <component
      :is="mapImplement"
      :mission="mission"
    />
    <map-title
      :group="group.name"
      :sub-title="drone.name"
    />
    <back-button
      :mission-name="mission.name"
      @back-click="back"
    />
    <waypoint-list
      ref="waypointList"
      :path="mission.tasks"
      :edit-mode="false"
    />
    <save-button
      :cancel-text="$t('button.cancel')"
      :text="$t('button.done')"
      @cancel="cancel"
      @save="done"
    />
    <reset-mission-dialog
      :is-display="showReplaceDialog"
      :title="$t('page.monitor.replaceMission')"
      @resetMission="resetMission"
      @cancel="back"
    />
  </admin-fillall-layout>
</template>

<script>
import { CopyObject } from '@/utils/common';
import { mapGetters, mapActions } from 'vuex';

import WaypointList from '@/components/Maps/WaypointList.vue';
import MapTitle from '@/components/Maps/MapTitle.vue';
import BackButton from '@/components/Maps/BackButton.vue';
import SaveButton from '@/components/Maps/SaveButton.vue';

import MissionApi from '@/services/api/domain/group/mission';
import DroneApi from '@/services/api/domain/group/drone';
import ResetMissionDialog from '@/components/Monitor/ResetMissionDialog';
import OpenLayersImplement from './Map-OpenLayers.vue';
import GoogleMapsImplement from './Map-GoogleMaps.vue';

export default {
  name: 'AssignMissionMap',
  components: {
    WaypointList,
    MapTitle,
    SaveButton,
    BackButton,
    ResetMissionDialog,
  },
  computed: {
    mapImplement() {
      switch (this.mapEngine) {
        case 'GoogleMaps':
          return GoogleMapsImplement;
        case 'OpenLayers':
          return OpenLayersImplement;
      }
    },
    ...mapGetters({
      group: 'user/group',
      mapEngine: 'user/mapEngine',
      triggerData: 'trigger/getTrigger',
    }),
  },
  data() {
    return {
      mission: {
        tasks: [],
        name: '',
      },
      drone: {
        name: '',
      },
      showReplaceDialog: false,
    };
  },
  async mounted() {
    const _this = this;
    if (this.$router.currentRoute.params.drone != null) {
      DroneApi.get(this.group.id, this.$router.currentRoute.params.drone).then((drone) => {
        _this.drone = drone.data;
      });
    }
    const mission = await MissionApi.get(this.group.id, this.$router.currentRoute.params.mission);
    this.mission = mission.data;
  },
  methods: {
    closeMenu(index) {
      this.$refs.waypointList.deActivate(index);
      this.$refs.path.marker.deActivate(index);
      this.map.activatePath = null;
    },
    cancel() {
      let { returnQuery } = this.$route.query;
      returnQuery = (returnQuery != null) ? JSON.parse(returnQuery) : {};
      this.$router.push({
        name: this.$route.query.return,
        query: returnQuery,
      });
    },
    back(result) {
      console.log('back');
      if (result) {
        this.$router.push({
          name: this.$route.query.return,
          // query: (returnQuery != null) ? JSON.parse(returnQuery) : null,
          // params: params,
        });
      }
      const { returnQuery } = this.$route.query;
      const params = {
        assign: true,
        missionId: this.mission.id,
        droneId: this.drone.id,
      };
      this.$router.push({
        name: this.$route.query.return,
        query: (returnQuery != null) ? JSON.parse(returnQuery) : null,
        params,
      });
    },
    async done() {
      if (this.$router.currentRoute.query.return == 'eventList') {
        const eventTrigger = CopyObject(this.triggerData);
        eventTrigger.mission = CopyObject(this.mission);
        this.setTrigger(eventTrigger);
        this.back();
        return;
      }
      const loading = this.$loading({
        lock: true,
      });

      try {
        const status = await DroneApi.assign(
          this.group.id,
          this.$router.currentRoute.params.drone,
          this.$router.currentRoute.params.mission,
        );
        this.$showSuccess(status.msg);
        this.back(true);
      } catch (error) {
        if (error.status === 403) {
          this.showReplaceDialog = true;
        } else {
          this.$showFail(error);
        }
      }

      loading.close();
    },
    async resetMission() {
      const loading = this.$loading({
        lock: true,
      });
      try {
        await DroneApi.incomplete(this.group.id, this.drone.id);
        const status = await DroneApi.assign(
          this.group.id,
          this.$router.currentRoute.params.drone,
          this.$router.currentRoute.params.mission,
        );
        this.$showSuccess(status.msg);
        this.back(true);
      } catch (error) {
        this.$showFail(error);
      }
      loading.close();
    },
    ...mapActions({
      setTrigger: 'trigger/setTrigger',
    }),
  },
};

</script>

<style lang="scss">
</style>
