<template>
  <div
    v-clickoutside="clickOutside"
    class="el-input fms-datetime-picker"
  >

    <!-- insert date-time-picker -->
    <el-date-picker
      v-model="dateValueString"
      type="datetime"
      ref="autoClose"
      :class="hasTime ? 'input-datetime-picker': 'input-date-picker'"
      :format="hasTime ? 'yyyy-MM-dd HH:mm': 'yyyy-MM-dd'"
      :popper-class="hasTime ? '' : 'date-time'"
    >
    </el-date-picker>
  </div>
</template>

<script>
import moment from 'moment';
import Clickoutside from 'element-ui/lib/utils/clickoutside.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DateTimePicker',
  directives: { Clickoutside },
  props: {
    value: {
      required: true,
    },
    minuteStep: {
      type: Number,
      default: 30,
    },
    formatString: {
      type: String,
      default: 'YYYY.MM.DD HH:mm',
    },
    timeFormat: {
      type: String,
      default: 'HH:mm',
    },
    hoverShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      previewValue: moment(),
      selecting: 'datetime',
      dateSelected: false,
      timeSelected: false,
      showForm: false,
      displayWeekday: null,
      selectableMonth: null,
      dateValueString: '',
      dateValue: '',
    };
  },
  created() {
    this.setLocale();
  },
  mounted() {
    if (this.value != null) {
      this.dateValueString = moment(this.value);
    }
  },
  methods: {
    setLocale() {
      moment.locale(this.appLang);
      this.$set(this, 'displayWeekday', moment.weekdaysMin());
      this.$set(this, 'selectableMonth', moment.monthsShort());
    },
    clickOutside() {
      this.showForm = false;
    },
  },
  computed: {
    hasTime() {
      return (this.minuteStep > 0);
    },
    ...mapGetters(['appLang']),
  },
  watch: {
    value() {
      if (!this.value) {
        this.dateValueString = '';
        return;
      }
      if (this.dateValueString.isSame && this.dateValueString.isSame(this.value)) {
      } else {
        this.dateValueString = moment(this.value)
          .clone();
      }
    },
    dateValueString() {
      if (!this.dateValueString) {
        this.$emit('input', '');
      } else {
        this.dateValue = moment(this.dateValueString);
        this.$emit('input', this.dateValue.clone());
        if (!this.hasTime) {
          this.$refs.autoClose.handleClose();
        }
      }
    },
    appLang() {
      this.setLocale();
    },
  },
};
</script>

<style lang="scss">
.fms-datetime-picker {
  .input-datetime-picker {
    width: 170px;

    .el-input__prefix {
      display: none;
    }
    .el-input__inner {
      padding-left: 10px;
      padding-right: 5px;
      cursor: pointer;
    }
    .el-input__suffix {
      display: none;
    }
  }
  .input-date-picker {
    //width: 110px;
    width: 100%;
    .el-input__prefix {
      display: none
    }
    .el-input__inner {
      padding-left: 10px;
      padding-right: 0;
      background-image: url('/asserts/plan-mission/Schedule_datepicker.svg');
      background-repeat: no-repeat;
      background-position: right 7% bottom 50%;
      background-color: transparent;
      cursor: pointer;
    }

    .el-input__suffix {
      display: none;
    }

  }
  .input-date-picker:hover {
    cursor: pointer;
  }
}

.date-time {
  .el-date-picker__time-header {
    display: none;
  }
  .el-picker-panel__footer {
    display: none;
  }
}
</style>
