<template>
  <div class="map-bottom flight-controller">
    <default-dialog
      :show-dialog="currentNotification != null && alertWindow.method == 'dialog'"
      :has-cancel="alertWindow.withCancel"
      :center="true"
      :width="alertWindow.width"
      next-button-text="OK"
      footer-align="center"
      @close="event => closeAlert(event == 'click-cancel', false)"
      @next="closeAlert(true, true)"
    >
      <template
        v-if="currentNotification"
        slot="main"
      >
        <div class="notification-body">
          <img src="/asserts/icon/popup-alert-icon.svg">
          <h2>{{ alertWindow.title }}</h2>
          {{ currentNotification.message }}
        </div>
      </template>
    </default-dialog>
    <default-dialog
      v-if="showClickAndGoDialogue"
      :show-dialog="true"
      :has-cancel="false"
      :next-button-text="$t('button.yes')"
      :center="true"
      width="558px"
      footer-align="center"
      @next="guidedMode"
    >
      <template slot="main">
        <div class="notification-body">
          {{ $t(`page.monitor.control.stop`) }}
        </div>
      </template>
      <template slot="footer">
        <el-button
          class="main-actions"
          type="info"
          @click="showClickAndGoDialogue = false"
        >
          {{ $t("button.no") }}
        </el-button>
      </template>
    </default-dialog>
    <reset-mission-dialog
      :is-display="showReplaceDialog"
      :title="$t('page.monitor.replaceMission')"
      @resetMission="resetMission"
      @cancel="showReplaceDialog = false"
    />

    <div
      class="close"
      @click="$emit('close')"
    >
      <img src="/asserts/icon/controlbar-hide-btn.svg">
    </div>

    <div class="flight-controller__content flight-information">
      <attitude-indicator
        :pitch="droneMq.attitude.pitchAngle"
        :roll="droneMq.attitude.rollAngle"
        :yaw="droneMq.attitude.yawAngle"
      />
      <div class="row">
        <div class="drone-name">
          <div class="drone-name__content">
            <img src="/asserts/icon/menu-devices-icon.svg">
            <span>{{ drone.name }}</span>
          </div>
        </div>
        <template>
          <div class="drone-status-light">
            <div :class="`light ${status.detail}`" />
          </div>
          <span class="drone-status">{{ $t(`page.monitor.status.${status.detail}`) }}</span>
          <!-- <span class="drone-status">On Mission</span> -->
        </template>
        <span
          v-if="droneMission"
          :title="droneMission.name"
          class="drone-mission-name"
        >
          {{ droneMission.name }}
        </span>
        <div
          v-if="droneMission"
          class="drone-mission-period"
        >
          <img src="/asserts/icon/functioncontrol-schedule-icon.svg">
          <span v-if="drone.next_schedule_time">{{ formatDateTime(drone.next_schedule_time) }}</span>
          <span v-else>--</span>
        </div>
      </div>
      <div class="row">
        <div class="stack-info">
          <label>{{ $t("page.monitor.control.battery") }}</label>
          <span v-if="droneMq.battery_status.remaining != null">{{ droneMq.battery_status.remaining }}%</span>
          <span v-else>--</span>
        </div>
        <div class="stack-info">
          <label>{{ $t("page.monitor.control.voltage") }}</label>
          <span
            v-if="!isNaN(droneMq.battery_status.voltage)"
          >{{ (droneMq.battery_status.voltage / 1000) | roundNumber(1) }}v</span>
          <span v-else>--</span>
        </div>
        <div class="stack-info small">
          <label>{{ $t("page.monitor.control.GPS") }}</label>
          <span v-if="droneMq.gps.satellites_visible">{{ droneMq.gps.satellites_visible }}</span>
          <span v-else>--</span>
        </div>
        <div class="stack-info">
          <label>{{ $t("page.monitor.control.flightTime") }}</label>
          <span>{{ flightTime | duration("mm:ss", "--") }}</span>
        </div>
        <div class="stack-info">
          <label>{{ $t("page.monitor.control.EstTime") }}</label>
          <span v-if="droneMission != null">{{ droneMission.duration | duration("mm:ss", "--") }}</span>
          <span v-else>--</span>
        </div>
        <div class="stack-info">
          <label>{{ $t("page.monitor.control.altitude") }}</label>
          <span v-if="droneMq.hud.alt != null">{{ getTransAltitude(droneMq.hud.alt) | roundNumber(1) }}{{ disUnitHint }}</span>
          <span v-else>--</span>
        </div>
        <div class="stack-info speed">
          <label>{{ $t("page.monitor.control.speed") }}</label>
          <span v-if="droneMq.hud.ground_speed != null">{{ getTransSpeed(droneMq.hud.ground_speed) | roundNumber(1) }}{{ speedUnitHint }}</span>
          <span v-else>--</span>
        </div>
        <div class="stack-info speed">
          <label>{{ $t("page.monitor.control.vSpeed") }}</label>
          <span v-if="droneMq.hud.climb != null">{{ getTransSpeed(droneMq.hud.climb) | roundNumber(1) }}{{ speedUnitHint }}</span>
          <span v-else>--</span>
        </div>

        <el-popover
          placement="top"
          trigger="click"
          width="380"
          height="91"
          class="action-button popover-button"
          popper-class="info-popover"
        >
          <div class="popover-outer">
            <div class="hidden-text-area">
              <span>{{ droneMq.gps.latitude | roundNumber(7) }}, {{ droneMq.gps.longitude | roundNumber(7) }}</span>
            </div>
            <div class="popover-inner-row">
              <div class="stack-info">
                <label>{{ $t("page.monitor.control.HDOP") }}</label>
                <span v-if="!isNaN(droneMq.gps.eph)">{{ getTransMicroAltitude(droneMq.gps.eph) }}{{ mDisUnitHint }}</span><!--oor epv-->
              </div>

              <div class="stack-info large">
                <label>{{ $t("page.monitor.control.linkQuality") }}</label>
                --
              </div>

              <div class="stack-info">
                <label>{{ $t("page.monitor.control.target") }}</label>
                <span v-if="awayFromTarget != null">{{ getTransAltitude(awayFromTarget) | roundNumber(0) }}{{ disUnitHint }}</span>
                <span v-else>--</span>
              </div>
              <div class="stack-info">
                <label>{{ $t("page.monitor.control.fromHome") }}</label>
                <span v-if="awayFromHome != null">{{ getTransAltitude(awayFromHome) | roundNumber(0) }}{{ disUnitHint }}</span>
                <span v-else>--</span>
              </div>

              <div class="stack-info-last">
                <label>{{ $t("page.monitor.control.life") }}</label>
                <span>{{ (droneMq.heartbeat.online_duration / 1000) | duration("d[d] hh:mm:ss", "--") }}</span><!--oor epv-->
              </div>
            </div>
          </div>
          <!-- <el-button slot="reference">hover 激活</el-button> -->

          <div
            slot="reference"
            class="info"
          >
            <i>i</i>
          </div>
        </el-popover>
      </div>
    </div>
    <div
      v-show="!isViewer"
      :class="{ 'flight-controller__content': true, 'flight-action': true, offline: status.detail == 'offline' }"
    >
      <div class="row">
        <button
          :disabled="!controlsEnable"
          class="flight-action__button image-button hover"
          @click="sendHover"
        />
        <button
          :disabled="!controlsEnable || forcedLand"
          class="flight-action__button image-button resume"
          @click="sendResume"
        />
        <button
          :class="{
            'flight-action__button': true,
            'image-button': true,
            [landOrTakeOffButtonIcon]: true
          }"
          :disabled="!controlsEnable || floating"
          @click="landOrTakeOff"
        />
        <button
          :disabled="!controlsEnable || !returnButtonEnable"
          class="flight-action__button image-button returnhome"
          @click="sendRTL"
        />
      </div>
      <div class="row">
        <button
          :class="{
            'flight-action__button': true,
            'image-button': true,
            clickandgo: true,
            activate: guided
          }"
          :disabled="!controlsEnable || floating"
          @click="clickGoConfirm"
        />
        <button
          :class="{
            'flight-action__button': true,
            'image-button': true,
            gimbalcontrol: true,
            activate: activateControl === 'gimbal'
          }"
          :disabled="!controlsEnable"
          @click="control('gimbal')"
        />
        <button
          :disabled="!controlsEnable"
          class="flight-action__button image-button start"
          @click="start"
        />
      </div>
    </div>
    <!-- flight-action under 1021px -->
    <div class="flight-action-row">
      <button
        :disabled="!controlsEnable"
        class="flight-action__button image-button hover"
        @click="sendHover"
      />
      <button
        :disabled="!controlsEnable || forcedLand"
        class="flight-action__button image-button resume"
        @click="sendResume"
      />
      <button
        :class="{
          'flight-action__button': true,
          'image-button': true,
          [landOrTakeOffButtonIcon]: true
        }"
        :disabled="!controlsEnable || floating"
        @click="landOrTakeOff"
      />
      <button
        :disabled="!controlsEnable || !returnButtonEnable"
        class="flight-action__button image-button returnhome"
        @click="sendRTL"
      />
      <button
        :class="{
          'flight-action__button': true,
          'image-button': true,
          clickandgo: true,
          activate: guided
        }"
        :disabled="!controlsEnable || floating"
        @click="clickGoConfirm"
      />
      <button
        :class="{
          'flight-action__button': true,
          'image-button': true,
          gimbalcontrol: true,
          activate: activateControl === 'gimbal'
        }"
        :disabled="!controlsEnable"
        @click="control('gimbal')"
      />
      <button
        :disabled="!controlsEnable"
        class="flight-action__button image-button start"
        @click="start"
      />
    </div>

    <!--  flight-action under 1021px end -->
    <gimbal-control
      v-if="activateControl === 'gimbal' && !floating"
      :drone="drone"
      :drone-mq="droneMq"
      :gimbal="gimbal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import duration from 'moment-duration-format';
import { getDistance } from '@/utils/mission';
import { DisMeasure, MDisMeasure, SpeedMeasure } from '@/utils/measure';

import AttitudeIndicator from '@/components/Monitor/AttitudeIndicator.vue';
import DroneApi from '@/services/api/domain/group/drone';
import GimbalControl from '@/components/Monitor/GimbalControl.vue';
import DefaultDialog from '@/components/DefaultDialog.vue';
import ResetMissionDialog from '@/components/Monitor/ResetMissionDialog';

export default {
  name: 'DroneControl',
  components: {
    AttitudeIndicator,
    GimbalControl,
    DefaultDialog,
    ResetMissionDialog,
  },
  props: {
    replay: {
      required: false,
      default: false,
      type: Boolean,
    },
    guided: {
      required: true,
      type: Boolean,
    },
    drone: {
      required: true,
      type: Object,
    },
    droneMission: {},
    droneMq: {
      required: true,
      type: Object,
    },
    status: {
      required: true,
      type: Object,
    },
    gimbal: {
      required: false,
      type: Number,
    },
  },

  data() {
    return {
      activateControl: null,
      executeLastTime: null,
      showClickAndGoDialogue: false,
      showReplaceDialog: false,
      forcedLand: false,
      currentNotification: null,
      alertWindow: {
        method: 'dialog',
        withCancel: false,
        title: 'Warning',
        width: '450px',
      },
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      isAdmin: 'user/isAdmin',
      isViewer: 'user/isViewer',
    }),
    flightTime() {
      const now = moment(this.droneMq.heartbeat.time);
      if (this.status.detail === 'onmission') {
        this.executeLastTime = now;
      }

      let startTime;
      if (this.droneMq.alert.time) {
        startTime = this.droneMq.alert.time;
      } else {
        startTime = this.drone.mission_execute_time;
      }

      if (startTime == null || this.executeLastTime == null) {
        return null;
      }

      return this.executeLastTime.diff(startTime) / 1000;
    },
    awayFromHome() {
      if (this.drone == null || this.droneMission == null || this.droneMq.timeout.alive != true) {
        return null;
      }
      const home = this.droneMission.tasks[0];
      const currentLocation = this.droneMq.gps;
      return getDistance(home.x, home.y, currentLocation.latitude, currentLocation.longitude);
    },
    awayFromTarget() {
      if (
        this.drone == null
        || this.droneMission == null
        || this.droneMq.current_mission.sequence == null
        || this.droneMq.timeout.offline === true
      ) {
        return null;
      }
      const targetIndex = this.droneMq.current_mission.sequence;
      let target = null;
      if (targetIndex <= -1) {
        return null;
      }
      if (targetIndex >= this.droneMission.tasks.length) {
        [target] = this.droneMission.tasks;
      } else {
        target = this.droneMission.tasks[targetIndex];
      }
      const currentLocation = this.droneMq.gps;
      return getDistance(target.x, target.y, currentLocation.latitude, currentLocation.longitude);
    },
    controlsEnable() {
      return !!(this.isAdmin && !this.replay && this.droneMq.timeout.alive);
    },
    landOrTakeOffButtonIcon() {
      switch (this.droneMq.heartbeat.drone_status) {
        case 'landing':
          return 'landing';
        case 'takingoff':
          return 'takingoff';
        case 'standby':
        case 'charging':
          return 'takeoff';
        default:
          return 'land';
      }
    },
    floating() {
      switch (this.droneMq.heartbeat.drone_status) {
        case 'landing':
        case 'takingoff':
          return true;
        default:
          return false;
      }
    },
    returnButtonEnable() {
      if (this.droneMq.heartbeat.drone_status === 'hovering' && !this.forcedLand) {
        return true;
      }
      return false;
    },
    speedUnitHint() {
      return SpeedMeasure.unit();
    },
    disUnitHint() {
      return DisMeasure.unit();
    },
    mDisUnitHint() {
      return MDisMeasure.unit();
    },
  },
  methods: {
    duration,
    async closeAlert(byHand, excute) {
      this.currentNotification = null;
    },

    async start() {
      const loading = this.$loading({
        lock: true,
      });
      try {
        const status = await DroneApi.execute(this.group.id, this.drone.id);
        this.$showSuccess(status.msg);
        this.forcedLand = false;
      } catch (error) {
        if (error.status === 403) {
          this.showReplaceDialog = true;
        } else {
          this.currentNotification = { message: error.data.meta.error };
        }
        // this.$showFail(error);
      }
      loading.close();
    },
    control(item) {
      if (this.activateControl === item) {
        this.activateControl = null;
        return;
      }

      this.activateControl = item;
    },
    formatDateTime(time) {
      return moment(time).format(this.$t('page.monitor.control.timeFormat'));
    },
    async sendHover() {
      // this.$mqtt.sendHover(this.group,this.drone);
      // this.$showInfo(this.$t('page.monitor.control.to_hover'));
      const loading = this.$loading({
        lock: true,
      });

      try {
        const status = await DroneApi.execute(this.group.id, this.drone.id, 'hover', {});
        this.$showInfo(this.$t('page.monitor.control.to_hover'));
      } catch (error) {
        console.error(error);
        this.$showFail(error);
      }
      loading.close();
    },
    guidedMode() {
      if (this.droneMq.heartbeat.drone_status !== 'hovering') {
        this.sendHover();
        this.showClickAndGoDialogue = false;
      }
      this.$emit('update:guided', true);
    },
    async sendResume() {
      // this.$mqtt.sendResume(this.group,this.drone);
      // this.$showInfo(this.$t('page.monitor.control.resume'));
      const loading = this.$loading({
        lock: true,
      });

      try {
        const status = await DroneApi.execute(this.group.id, this.drone.id, 'resume', {});
        this.$showInfo(this.$t('page.monitor.control.resume'));
      } catch (error) {
        console.error(error);
        this.$showFail(error);
      }
      loading.close();
    },
    clickGoConfirm() {
      if (this.guided === true) {
        this.$emit('update:guided', false);
        return;
      }
      switch (this.droneMq.heartbeat.drone_status) {
        case 'hovering':
        case 'clickngo':
          this.guidedMode();
          return;
        default:
          this.showClickAndGoDialogue = true;
      }
    },
    landOrTakeOff() {
      switch (this.droneMq.heartbeat.drone_status) {
        case 'landing':
        case 'takingoff':
          console.warn('button should disabled');
          return;
        case 'standby':
        case 'charging':
          this.$emit('popTakeoff');
          return;
        case 'hovering':
        default:
          this.sendLand();
      }
    },
    async sendLand() {
      // this.$mqtt.sendLand(this.group,this.drone);
      // this.$showInfo(this.$t('page.monitor.control.land'));
      // this.forcedLand=true;
      const loading = this.$loading({
        lock: true,
      });

      try {
        const status = await DroneApi.execute(this.group.id, this.drone.id, 'land', {});
        this.forcedLand = true;
        this.$showInfo(this.$t('page.monitor.control.land'));
      } catch (error) {
        console.error(error);
        this.$showFail(error);
      }
      loading.close();
    },
    // async sendTakeOff(){
    //   let status = await DroneApi.execute(this.group.id, this.drone.id, 'take_off', {
    //   altitude: 100,
    //   });

    //   // this.$mqtt.sendTakeOff(this.group,this.drone);
    // },
    sendRTL() {
      this.$emit('popRTL');
      // this.$mqtt.sendRTL(this.group,this.drone);
    },
    async resetMission() {
      const loading = this.$loading({
        lock: true,
      });
      try {
        await DroneApi.incomplete(this.group.id, this.drone.id);
      } catch (error) {
        this.$showFail(error);
      }
      this.showReplaceDialog = false;
      setTimeout(async () => {
        try {
          const status = await DroneApi.execute(this.group.id, this.drone.id);
          this.$showSuccess(status.msg);
          this.forcedLand = false;
        } catch (error) {
          this.currentNotification = { message: error.data.meta.error };
        } finally {
          loading.close();
        }
      }, 10000);
    },
    getTransSpeed(value, digit = 1) {
      return SpeedMeasure.display(value, digit, 0);
    },
    getTransAltitude(value, digit = 1) {
      return DisMeasure.display(value, digit, 0);
    },
    getTransMicroAltitude(value, digit = 1) {
      return MDisMeasure.display(value, digit, 0);
    },
  },
};
</script>

<style lang="scss">
$attitude-indicator-preserve: 146px;
.flight-controller {
  height: 107px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 14px;
  @include tablet-extra-large-1021px {
    display: flex;
    justify-content: center;
  }

  .close {
    position: absolute;
    width: 64px;
    height: 25px;
    top: -24px;
    right: 2px;
    cursor: pointer;
  }

  .flight-controller__content {
    margin-top: 9px;
    margin-bottom: 5px;
    height: 93px;
    float: left;

    .row {
      display: table;
    }
  }

  .flight-information {
    margin-left: $attitude-indicator-preserve;
    width: 530px;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.3);
    position: relative;

    @include tablet-extra-large-1021px {
      border-style: none;
    }

    .row {
      height: 31px;
      margin-bottom: 17px;
      text-align: center;
    }

    :last-child.row {
      margin-bottom: 0;
    }
    .row > * {
      display: table-cell;
      vertical-align: middle;
      line-height: 22px;
    }

    .drone-name {
      width: 193px;
      height: 31px;
      border-radius: 4px;
      background-color: black;
      top: 18px;
      display: table;

      .drone-name__content {
        text-align: left;
        display: table-cell;
        vertical-align: middle;
      }
      .drone-name__content > * {
        vertical-align: middle;
      }
      .drone-name__content > img {
        padding-left: 9px;
        padding-right: 2px;
      }
    }

    .drone-status-light {
      padding-left: 10px;
      padding-right: 1px;
    }

    .drone-status {
      // width should include padding
      width: 71px;
      font-size: 12px;
      text-align: left;
      padding: 0px 5px;
    }

    .drone-latlng {
      padding-left: 16px;
    }

    .drone-mission-name {
      text-align: left;
      padding: 0 5px;
      width: 114px;
      max-width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .drone-mission-period {
      padding-left: 5px;
      padding-right: 5px;
      * {
        vertical-align: middle;
      }
    }

    .stack-info {
      font-size: 16px;
      // padding-right: 15px;
      width: 63px;

      label {
        font-size: 13px;
        display: block;
        text-align: center;
        color: #d0d0d0;
      }

      &.speed {
        width: 66px;
      }
    }

    .large {
      width: 85px;
    }

    .small {
      width: 45px;
    }
  }

  .flight-action {
    @include tablet-extra-large-1021px {
      display: none;
    }
    padding-left: 12px;
    overflow: hidden;
    .row {
      margin-bottom: 16px;
      .flight-action__button {
        float: left;
        margin-right: 12px;
      }
      .flight-action__button.start {
        width: 128px;
        background-image: url("/asserts/icon/function-control-start-btn-normal.svg");
      }
      .flight-action__button.start:hover {
        background-image: url("/asserts/icon/function-control-start-btn-focus.svg");
      }
    }
  }

  .flight-action,
  .flight-action-row {
    .flight-action__button {
      width: 58px;
      height: 34px;
    }
    .flight-action__button:disabled {
      cursor: not-allowed;
    }

    .flight-action__button:last-child {
      margin-right: 0;
    }

    .flight-action__button.hover {
      // cursor: not-allowed;
      background-image: url("/asserts/icon/function-control-hover-btn-normal.svg");
    }
    .flight-action__button.hover:hover {
      background-image: url("/asserts/icon/function-control-hover-btn-focus.svg");
    }

    .flight-action__button.resume {
      background-image: url("/asserts/icon/function-control-resume-btn-normal.svg");
    }
    .flight-action__button.resume:hover {
      background-image: url("/asserts/icon/function-control-resume-btn-focus.svg");
    }

    .flight-action__button.land {
      background-image: url("/asserts/icon/function-control-landing-btn.svg");
    }

    .flight-action__button.land:hover {
      background-image: url("/asserts/icon/function-control-landing-btn-focus.svg");
    }

    .flight-action__button.landing {
      cursor: not-allowed;
      background-image: url("/asserts/icon/function-control-landing-btn-focus.svg");
    }

    .flight-action__button.landing:hover {
      background-image: url("/asserts/icon/function-control-landing-btn-focus.svg");
    }

    .flight-action__button.takeoff {
      background-image: url("/asserts/icon/function-control-takeoff-btn.svg");
    }

    .flight-action__button.takeoff:hover {
      background-image: url("/asserts/icon/function-control-takeoff-btn-focus.svg");
    }

    .flight-action__button.takingoff {
      cursor: not-allowed;
      background-image: url("/asserts/icon/function-control-takeoff-btn-focus.svg");
    }

    .flight-action__button.takingoff:hover {
      background-image: url("/asserts/icon/function-control-takeoff-btn-focus.svg");
    }

    .flight-action__button.returnhome {
      // cursor: not-allowed;
      background-image: url("/asserts/icon/function-control-returnhome-btn-normal.svg");
    }
    .flight-action__button.returnhome:hover {
      background-image: url("/asserts/icon/function-control-returnhome-btn-focus.svg");
    }

    .flight-action__button.clickandgo {
      // cursor: not-allowed;
      background-image: url("/asserts/icon/function-control-clickandgo-btn-normal.svg");
    }
    .flight-action__button.clickandgo:hover,
    .flight-action__button.clickandgo.activate {
      background-image: url("/asserts/icon/function-control-clickandgo-btn-focus.svg");
    }

    .flight-action__button.gimbalcontrol {
      background-image: url("/asserts/icon/function-control-gimbalcontrol-btn-normal.svg");
    }
    .flight-action__button.gimbalcontrol:hover,
    .flight-action__button.gimbalcontrol.activate {
      background-image: url("/asserts/icon/function-control-gimbalcontrol-btn-focus.svg");
    }
  }

  .flight-action-row {
    display: none;
    @include tablet-extra-large-1021px {
      width: 466px;
      height: 46px;
      margin-left: 97px;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: -55px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 4px;
      .flight-action__button.start {
        background-image: url("/asserts/icon/function-control-start-btn-small-normal.svg");
      }
      .flight-action__button.start:hover {
        background-image: url("/asserts/icon/function-control-start-btn-small-focus.svg");
      }
    }
  }

  .info {
    top: 11px;
    right: -6px;
    color: #5c5c5c;
    font-size: 12px;
    line-height: 15px;
    background-color: #bfbfbf;
    width: 14px;
    height: 14px;
    -webkit-clip-path: circle();
    clip-path: circle();
    position: relative;
    cursor: pointer;
  }
}

.el-popover.el-popper.info-popover {
  .popper__arrow::after {
    border-top-color: black !important;
  }
}

.info-popover {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.8);
  height: 91px;
  border: none;

  .popover-outer {
    flex-grow: 1;
    word-break: break-all;
    // padding-right: 15px;
    .hidden-text-area {
      border: 1px;
      width: 155px;
      resize: none;
      border-style: solid;
      border-color: white;
      color: white;
      font-size: 12px;
      > span {
        margin-left: 5px;
      }
    }
    .popover-inner-row {
      display: flex;
      margin-top: 10px;
      .stack-info {
        font-size: 16px;
        padding-right: 3px;
        text-align: center;
        width: 60px;
        label {
          font-size: 13px;
          display: block;
          text-align: center;
          color: #d0d0d0;
        }

        span {
          color: white;
        }
      }

      .large {
        width: 85px;
      }

      .stack-info-last {
        font-size: 16px;
        padding-right: 3px;
        text-align: center;
        width: 103px;
        label {
          font-size: 13px;
          display: block;
          text-align: center;
          color: #d0d0d0;
        }

        span {
          color: white;
        }
      }
    }
  }
}
.el-message.el-message--info {
  background-color: rgba(0, 0, 0, 0.7);
  min-width: 352px;
  top: 106px;
  left: 53%;
  text-align: center;
  display: block;
  .el-message__icon.el-icon-info {
    display: none;
  }
  .el-message__content {
    color: #ffffff;
    text-align: center;
  }
}
.map-bottom.flight-controller {
  display: flex;
  justify-content: center;

  .notification-body > * {
    text-align: center;
    margin: 20px 0;
    h2 {
      margin: 0;
      font-size: 22px;
      font-weight: normal;
      line-height: 22px;
    }
  }
}
</style>
