<template>
  <div>
    <div class="map-body">
      <component
        :is="mapImplement"
        ref="maps"
        :select-drone="selectDrone"
        :map-option="map"
        :activate-drone="activateDrone"
        :drones="drones"
        :drone-mq="droneMq"
        :drone-mq-history="droneMqHistory"
        :drone-status="droneStatus"
        :drone-mission="missions"
        :drone-guide="guidPoint"
        :popup-position="popupContent && popupContent.position"
        :popup-icon="popupContent && popupContent.icon"
        :popup-polyline="popupPolyline"
        @update:popupPosition="(p) => popupContent.position = p"
        @map-click="mapClick"
        @doubleClick="mapDoubleClick"
        @closePopup="popupContent = null"
        @popupDragend="popupDragend"
      >
        <template
          v-if="popupContent"
          slot="popup"
        >
          <!-- slot="pop" is Deprecated in 2.6.0
            https://vuejs.org/v2/guide/components-slots.html#Deprecated-Syntax
          -->
          <div class="fms-infowindow-container altitude-editor">
            <h2>{{ $t(popupContent.titleKey) }}</h2>
            <el-form :model="popupContent">
              <el-form-item :label="$t('page.monitor.control.Altitude')">
                <el-input-number
                  v-model="popupContent.altitude"
                  :min="group.rtl_altitude"
                  :max="120"
                  size="mini"
                />
              </el-form-item>

              <el-form-item :label="$t('page.monitor.speed')">
                <el-input-number
                  v-model="popupContent.speed"
                  :min="1"
                  :max="20"
                  size="mini"
                />
              </el-form-item>

              <template v-if="popupContent.position && popupContent.editPosition">
                <el-form-item :label="$t('page.monitor.control.Latitude')">
                  <el-input
                    v-model="popupContent.position.latitude"
                    size="mini"
                    disabled
                  />
                </el-form-item>

                <el-form-item :label="$t('page.monitor.control.longitude')">
                  <el-input
                    v-model="popupContent.position.longitude"
                    size="mini"
                    disabled
                  />
                </el-form-item>
              </template>
            </el-form>
            <section class="footer">
              <el-button
                :disabled="!isAdmin"
                size="mini"
                type="primary"
                class="full-size"
                @click="popupSave"
              >
                {{ $t('button.rtlGo') }}
              </el-button>
            </section>
          </div>
        </template>
      </component>
      <nest-control
        v-if="$refs.dronControl == null || $refs.dronControl.activateControl == null"
        :nests="nests"
      />

      <div class="overlay gimbal-select">
        <el-select
          v-if="selectDrone && selectDrone.rgb_url && selectDrone.model=='G5.2'"
          v-model="gimbal_selected"
          :disabled="simp_gimbal_list.length<=1"
          placeholder="Select Gimbal"
          @change="updateGimbalSelect"
        >
          <el-option
            v-for="(item,index) in simp_gimbal_list"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>

      <div class="overlay player">
        <!-- <html-rtmp-player :url="selectDrone.rgb_url" v-if="selectDrone && selectDrone.rgb_url" /> -->
        <flash-rtmp-player
          v-if="selectDrone && selectDrone.rgb_url && !showCarDetection"
          :url="selectDrone.rgb_url"
        />
      </div>
    </div>
    <car-detection
      v-if="showCarDetection"
      :event="droneMq[selectDroneIndex].event"
      :video-url="selectDrone.rgb_url"
      @close="closeCarDetection"
    />
    <drone-control
      v-else-if="selectDrone"
      ref="dronControl"
      :drone="selectDrone"
      :drone-mq="droneMq[selectDroneIndex]"
      :drone-mission="missions[selectDroneIndex]"
      :status="droneStatus[selectDroneIndex]"
      :gimbal="gimbal"
      :guided.sync="guided"
      @close="closeController"
      @popTakeoff="showTakeoff"
      @popRTL="showRTL"
    />
    <drone-selector
      v-else
      :drones="drones"
      :drone-mq="droneMq"
      :status-list="droneStatus"
      @controllDrone="controllDrone"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import { Icon } from '@/components/GoogleMaps/MarkerStyle';
import TitleBar from '@/components/Monitor/TitleBar.vue';
import AttitudeIndicator from '@/components/Monitor/AttitudeIndicator.vue';
import DroneSelector from '@/views/admin/monitor/map/DroneSelector.vue';
import DroneControl from '@/views/admin/monitor/map/DroneControl.vue';
import Drone from '@/components/GoogleMaps/Drone.vue';
import NestControl from '@/views/admin/monitor/map/NestControl.vue';

import FlightMap from '@/components/GoogleMaps/FlightMap.vue';
import FlyRouter from '@/components/GoogleMaps/FlyRoute.vue';
import FlashRtmpPlayer from '@/components/Monitor/FlashRtmpPlayer.vue';
import HtmlRtmpPlayer from '@/components/Monitor/HtmlRtmpPlayer.vue';
import { logger } from '@/logger/index';
import DroneApi from '@/services/api/domain/group/drone';
import NestApi from '@/services/api/domain/group/nest';
import GoogleMapsImplement from './Index-GoogleMaps.vue';
import OpenLayersImplement from './Index-OpenLayers.vue';
import CarDetection from './CarDetection.vue';

export default {
  name: 'MonitorMap',
  components: {
    Drone,
    FlightMap,
    FlyRouter,
    AttitudeIndicator,
    DroneSelector,
    DroneControl,
    TitleBar,
    FlashRtmpPlayer,
    HtmlRtmpPlayer,
    NestControl,
    CarDetection,
  },
  props: {
    drones: {
      required: true,
      type: Array,
    },
    activateDrone: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectDrone: null,
      selectDroneIndex: null,
      nests: [],
      map: {
        drone: true,
        nest: true,
        camera: true,
      },
      popupContent: null,
      guided: false,
      guidPoint: [],
      tempMakrs: [],
      newTempMarks: [],
      tempMarks: null,
      model: [{
        id: 'gimbal1',
        name: 'gimbal1',
        value: 1,
      }, {
        id: 'gimbal2',
        name: 'gimbal2',
        value: 2,
      }],
      gimbal: 1,

      // gimbal_list: ["Gimbal 1", "Gimbal 2"],
      gimbal_selected: '',
      zoom_control_val_1: 20,
      zoom_control_val_2: 30,
      control_h: '150px',

      payload_info_selected: null,
      payload_control: {
        zoom: 1,
        focal_len: 1,
      },
      showCarDetection: false,
    };
  },
  computed: {

    gimbal_list() {
      if (this.selectDrone !== null) {
        const glist = this.selectDrone.model === 'G5.0' ? ['Gimbal 1'] : ['Gimbal 1', 'Gimbal 2'];
        return glist;
      }

      return [];
    },
    simp_gimbal_list() {
      if (this.gimbal_list.length > 1) {
        return this.gimbal_list.filter((e) => e.length > 0);
      }

      return [];
    },
    mapImplement() {
      switch (this.mapEngine) {
        case 'OpenLayers':
          return OpenLayersImplement;
        case 'GoogleMaps':
        default:
          return GoogleMapsImplement;
      }
    },
    ...mapGetters({
      mapEngine: 'user/mapEngine',
      group: 'user/group',
      isAdmin: 'user/isAdmin',
      droneLatestMessage: 'mqtt/droneLatestMessage',
      droneHistoryMessage: 'mqtt/droneHistoryMessage',
      droneStatusMessage: 'mqtt/droneStatus',
    }),
    popupPolyline() {
      if (this.popupContent != null && this.popupContent.action !== 'clicknGo') {
        const current = this.droneMq[this.selectDroneIndex].gps;
        const next = this.popupContent.position;
        return [current, next];
      }
      return [];
    },
    missions() {
      return CopyObject(this.drones).map((drone, index) => {
        let { mission } = drone;
        if (this.droneMq[index].alert && this.droneMq[index].alert.mission) {
          mission = CopyObject(this.droneMq[index].alert.mission);
        }
        if (mission == null) {
          return null;
        }
        // adjust takeoff
        // take off could happen in the middle of any routes during mission, these code is temporirly commented out
        if (mission.tasks[0].command == 'takeoff') {
          if (this.droneMq[index].take_off) {
            const lastPosition = this.droneMq[index].take_off;
            if (lastPosition.latitude != null && mission.tasks[0].x != lastPosition.latitude) {
              mission.tasks[0].x = lastPosition.latitude;
            }

            if (lastPosition.latitude != null && mission.tasks[0].y != lastPosition.longitude) {
              mission.tasks[0].y = lastPosition.longitude;
            }
          }
        }
        if (this.droneMq[index].current_mission == null
            || this.droneMq[index].current_mission.sequence == null
        ) {
          // console.log('not flying || no data',this.droneMq[index].current_mission);
          return mission;
        }
        const currentTask = this.droneMq[index].current_mission.sequence;
        for (const taskIndex in mission.tasks) {
          if (taskIndex < currentTask) {
            this.$set(mission.tasks[taskIndex], 'finished', true);
          } else {
            this.$set(mission.tasks[taskIndex], 'finished', false);
          }
          if (currentTask == taskIndex) {
            this.$set(mission.tasks[taskIndex], 'next', true);
          } else {
            this.$set(mission.tasks[taskIndex], 'next', false);
          }
        }
        if (currentTask > mission.tasks.length - 1) {
          mission.tasks[0].command = 'land';
          mission.tasks[0].next = true;
        } else {
          mission.tasks[0].command = 'takeoff';
        }
        return mission;
      });
    },
    droneMq() {
      return this.drones.map((drone) => {
        const dronePosition = {
          latitude: drone.latitude,
          longitude: drone.longitude,
        };
        if (this.droneLatestMessage[drone.id] == null) {
          return {};
        }
        return {
          id: drone.id,
          trigger: drone.trigger,
          heartbeat: this.droneLatestMessage[drone.id].heartbeat
            ? this.droneLatestMessage[drone.id].heartbeat : {},
          attitude: this.droneLatestMessage[drone.id].attitude
            ? this.droneLatestMessage[drone.id].attitude : {},
          battery_status: this.droneLatestMessage[drone.id].battery_status
            ? this.droneLatestMessage[drone.id].battery_status : {},
          current_mission: this.droneLatestMessage[drone.id].current_mission
            ? this.droneLatestMessage[drone.id].current_mission : {},
          gps: this.droneLatestMessage[drone.id].gps
            ? this.droneLatestMessage[drone.id].gps : dronePosition,
          hud: this.droneLatestMessage[drone.id].hud
            ? this.droneLatestMessage[drone.id].hud : {},
          pilot_status: this.droneLatestMessage[drone.id].pilot_status
            ? this.droneLatestMessage[drone.id].pilot_status : {},
          system_status: this.droneLatestMessage[drone.id].system_status
            ? this.droneLatestMessage[drone.id].system_status : {},
          event: this.droneLatestMessage[drone.id].event
            ? this.droneLatestMessage[drone.id].event : {},
          timeout: this.droneLatestMessage[drone.id].timeout
            ? this.droneLatestMessage[drone.id].timeout : {},
          alert: this.droneLatestMessage[drone.id].alert
            ? this.droneLatestMessage[drone.id].alert : {},
          take_off: this.droneLatestMessage[drone.id].take_off
            ? this.droneLatestMessage[drone.id].take_off : {},
          gimbal1_tilt: this.droneLatestMessage[drone.id].gimbal1_tilt
            ? this.droneLatestMessage[drone.id].gimbal1_tilt : {},
          gimbal2_tilt: this.droneLatestMessage[drone.id].gimbal2_tilt
            ? this.droneLatestMessage[drone.id].gimbal2_tilt : {},
          counting: this.droneLatestMessage[drone.id].counting
            ? this.droneLatestMessage[drone.id].counting : {},
        };
      });
    },
    droneMqHistory() {
      return this.drones.map((drone) => ({
        gps: this.droneHistoryMessage[drone.id].gps
          ? this.droneHistoryMessage[drone.id].gps : [],
      }));
    },
    droneStatus() {
      return this.drones.map((drone, index) => {
        const status = this.droneStatusMessage[drone.id];
        if (status != null) {
          return status;
        }
        return {
          detail: 'offline',
          simple: 'offline',
        };
      });
    },
    droneInCounting() {
      return this.droneMq.findIndex((drone, index) => this.isDroneInCounting(index));
    },
    droneIds() {
      return this.drones.map((drone) => drone.id);
    },
  },
  watch: {
    gimbal_selected(now) {
      let gb = '-';
      if (now == 'Gimbal 1') {
        gb = this.selectDrone.gimbal1;
      } else if (now == 'Gimbal 2') {
        gb = this.selectDrone.gimbal2;
      }
      const payload_id = gb.split('-')[0];
      const groupId = this.selectDrone.group;
      const did = this.selectDrone.id;
      if (payload_id.length > 0) {
        DroneApi.getPayloadInfowithPID(groupId, did, payload_id).then((res) => {
          this.payload_info_selected = res.data;
        });
      } else {
        // reset payload info selected
        this.payload_info_selected = null;
      }
    },
    gimbal_list(now) {
      if (now.length == 1) {
        this.gimbal_selected = now[0];
      }
    },
    guided(now) {
      if (now == false && this.popupContent != null && this.popupContent.actionType === 'clicknGo') {
        this.popupContent = null;
      }
    },
    droneStatus(now) {
      const total = this.drones.length;
      const statistics = this.droneStatus.reduce((acc, currentValue) => {
        acc[currentValue.simple] += 1;
        return acc;
      }, {
        standby: 0,
        charging: 0,
        error: 0,
        offline: 0,
      });
      this.$emit('update:statistics', {
        total,
        ...statistics,
      });
    },
    droneMq(current, previous) {
      // watch mq for cleanup click and go status
      let index = this.selectDroneIndex;
      if (index != null) {
        if (!current[index].timeout.alive) {
          this.guided = false;
          this.popupContent = null;
        }
      }
      for (index in current) {
        if (current[index].timeout == null || previous[index].alert == null) {
          continue;
        }
        if (!current[index].timeout.alive) {
          this.guidPoint[index] = null;
        }
        if (current[index].alert.time !== previous[index].alert.time) {
          this.guidPoint[index] = null;
        }
      }
    },
    drones(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }
      for (const index in old) {
        this.unsubscribeDrone({ drone: old[index] });
      }
      for (const index in now) {
        this.subscribeDrone({ drone: now[index] });
      }
    },
    nests(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }

      for (const index in old) {
        this.unsubscribeNest({ nest: old[index] });
      }
      for (const index in now) {
        this.subscribeNest({ nest: now[index] });
      }
    },
    droneInCounting(now) {
      if (now !== -1) {
        this.controllDrone(now);
      } else {
        this.showCarDetection = false;
      }
    },
  },
  async mounted() {
    let nests = await NestApi.getAll(this.group.id);
    let drones = await DroneApi.getAll(this.group.id);
    nests = nests.data.nests.filter((nest) => !nest.inactive);
    this.nests = nests;
    drones = drones.data.drones.filter((drone) => !drone.inactive);
    this.$emit('update:drones', drones);
  },
  destroyed() {
    for (const index in this.drones) {
      this.unsubscribeDrone({ drone: this.drones[index] });
    }
    for (const index in this.nests) {
      this.unsubscribeNest({ nest: this.nests[index] });
    }
    if (this.selectDrone) {
      this.closeController();
    }
  },
  methods: {
    isDroneInCounting(index) {
      const drone = this.droneMq[index];
      // console.log(drone);
      const status = this.droneStatusMessage[drone.id];
      const state = status != null ? status.detail : 'offline';
      const counting = (drone.counting.trigger != null) ? drone.counting.trigger : drone.trigger;
      return state !== 'offline' && counting === true;
    },
    updateGimbalSelect(item) {
      if (item == 'Gimbal 2') { this.gimbal = 2; } else { this.gimbal = 1; }
      // this.$showInfo('updateGimbalSelect index=' + this.gimbal);
    },
    closeCarDetection() {
      this.showCarDetection = false;
      this.closeController();
    },
    popupDragend() {
      if (this.popupContent != null) {

      } else {
        this.clicknGo();
      }
    },
    popupSave() {
      switch (this.popupContent.actionType) {
        case 'takeoff':
          this.takeoff();
          break;
        case 'rtl':
          this.returnHome();
          break;
        case 'clicknGo':
          this.clicknGo();
      }
    },
    async takeoff() {
      logger.debug('takeoff', this.popupContent);
      const loading = this.$loading({
        lock: true,
      });
      try {
        await DroneApi.execute(this.group.id, this.selectDrone.id, 'take_off', {
          altitude: this.popupContent.altitude,
          speed: this.popupContent.speed,
        });
        this.$showInfo(this.$t('page.monitor.control.takeoff'));
      } catch (e) {
        this.$showFail(e);
      } finally {
        this.popupContent = null;
        loading.close();
        logger.debug('enter guided mode');
      }
    },
    async returnHome() {
      logger.debug('returnHome', this.popupContent);
      const loading = this.$loading({
        lock: true,
      });
      try {
        await DroneApi.execute(this.group.id, this.selectDrone.id, 'rtl', {
          altitude: this.popupContent.altitude,
          speed: this.popupContent.speed,
        });
        this.$showInfo(this.$t('page.monitor.control.rtl'));
      } catch (e) {
        this.$showFail(e);
      } finally {
        this.popupContent = null;
        loading.close();
      }
    },
    async clicknGo() {
      logger.debug('clicknGo', this.popupContent);
      const loading = this.$loading({
        lock: true,
      });
      const { position } = this.popupContent;
      try {
        const status = await DroneApi.execute(this.group.id, this.selectDrone.id, 'guided', {
          altitude: this.popupContent.altitude,
          speed: this.popupContent.speed,
          path: [position],
        });
        this.$showInfo(this.$t('page.monitor.control.guided'));
        this.$set(this.guidPoint, this.selectDroneIndex, position);
        loading.close();
        this.popupContent = null;
        return true;
      } catch (error) {
        console.error(error);
        this.$showFail(error);
        loading.close();
        return false;
      }
    },
    async clicknGoReplace(altitude, speed, position) {
      if (await this.clicknGo(altitude, speed, position)) {
        const o = this.newTempMarks.pop();
        logger.debug(o);
        this.tempMakrs[0].x = o.x;
        this.tempMakrs[0].y = o.y;
      }
    },
    closeController() {
      this.selectDrone = null;
      this.selectDroneIndex = null;
      this.guided = false;
      this.popupContent = null;
      this.$store.commit('mqtt/MQTT_REMOVE_MONITOR_DRONE');
    },
    openController(index) {
      this.selectDrone = this.drones[index];
      this.selectDroneIndex = index;
      if (this.isDroneInCounting(index)) {
        this.showCarDetection = true;
      }
      this.$store.commit('mqtt/MQTT_ADD_MONITOR_DRONE', this.drones[index].id);
    },
    controllDrone(index) {
      if (this.selectDrone != null) {
        this.closeController();
      }
      this.$nextTick(() => this.openController(index));
    },
    showTakeoff() {
      this.$set(this, 'popupContent', {
        actionType: 'takeoff',
        titleKey: 'page.monitor.control.take_off',
        altitude: 15,
        speed: 1,
        position: {
          latitude: this.droneMq[this.selectDroneIndex].gps.latitude,
          longitude: this.droneMq[this.selectDroneIndex].gps.longitude,
        },
      });
    },
    showRTL() {
      this.$set(this, 'popupContent', {
        actionType: 'rtl',
        titleKey: 'page.monitor.control.rtlTitle',
        altitude: 15,
        speed: 1,
        position: {
          ...this.droneMq[this.selectDroneIndex].gps,
        },
      });
    },
    mapClick(event) {
      if (event.source === 'drone') {
        this.controllDrone(event.index);
      }
      if (this.guided && event.source === 'fence') {
        this.guideDrone(event);
      }
    },
    mapDoubleClick(event) {
      console.log(event);
    },
    simulateDrone(event) {
      if (this.$config.environment == 'development') {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        DroneApi.simulate(this.group.id, this.drones[0].id, {
          command: 'start',
          latitude: lat,
          longitude: lng,
        });
      }
    },
    guideDrone(event) {
      logger.debug('event:', event);
      if (this.$refs.dronControl.floating || this.$refs.dronControl.forcedLand) {
        return;
      }
      let icon;
      if (this.droneMq[this.selectDroneIndex].heartbeat.drone_status !== 'hovering') {
        this.$refs.dronControl.sendHover();
      }
      if (this.guidPoint[this.selectDroneIndex] != null) {
        icon = Icon.newClickAndGo;
      } else {
        icon = Icon.clickAndGo;
      }
      this.$set(this, 'popupContent', {
        actionType: 'clicknGo',
        titleKey: 'page.monitor.control.wpt',
        altitude: 15,
        speed: 1,
        position: event.position,
        icon,
      });
    },
    ...mapActions({
      subscribeDrone: 'mqtt/subscribeDrone',
      unsubscribeDrone: 'mqtt/unsubscribeDrone',
      subscribeNest: 'mqtt/subscribeNest',
      unsubscribeNest: 'mqtt/unsubscribeNest',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.map-body {
  position: absolute;
  top: 48px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background-color: black;
}
.overlay.player {
  // top: 15px;
  left: 15px;
  margin-top :15px;
  width: 220px;
  height: 123.4px;
  position: relative;
}

.overlay.zoom-control {
  top: 150px;
  right: 15px;
  left: auto;

  height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .block {
    display: flex;
    align-items: center;

    .content{
      height: 99px;
      width: 100px;
      border-radius: 50px;
      background-color: gray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.overlay.gimbal-select {
  // top: 15px;
  left: 15px;
  margin-top: 15px;
  width: 220px;
  height: 30px;
  margin-left: 12px;
  position: relative;
}

.overlay.gimbal {
  // top: 15px;
  left: 15px;
  margin-top :15px;
  width: 220px;
  height: 30px;
  position: relative;
}
.map-bottom {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.overlay.vertical-group-button {
  bottom: 140px !important;
}
.fms-infowindow-container.altitude-editor {
  width: 220px;
  .footer {
    text-align: center;
  }
  .el-input-number__decrease, .el-input-number__increase {
    background-color: #d2d2d2;
  }
  .el-input--mini {
    font-size: 14px;
  }
  .full-size{
    width:220px;
  }
}
</style>
