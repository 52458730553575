export default {
  tableColumnProps: [
    {
      label: 'page.mission.map',
      prop: 'mapUrl',
      width: '144px',
      minWidth: null,
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.mission.name',
      type: null,
      columnKey: null,
      prop: 'name',
      width: null,
      minWidth: '124px',
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.mission.endTime',
      type: null,
      columnKey: null,
      prop: 'end_time',
      width: '154px',
      minWidth: null,
      sortable: 'custom',
      resizable: null,
      searchable: false,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.mission.location',
      type: null,
      columnKey: null,
      prop: 'location',
      width: '99px',
      minWidth: null,
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.mission.period',
      type: null,
      columnKey: null,
      prop: 'period',
      width: '136px',
      minWidth: null,
      sortable: null,
      resizable: null,
      searchable: false,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
  ],
};