<template>
  <div class="camera-list">
    <div class="title">{{ $t('page.monitor.cameraList.title') }}</div>
    <div class="category">
      <div class="title" v-on:click="toggleCollapse('drone')">
        <div :class="`triangle-clip ${(collapse.drone) ? 'collapse' : 'expand'}`"></div>
        {{ $t('page.monitor.cameraList.drones') }}
      </div>
      <div class="category__content" v-show="!collapse.drone">
        <template v-for="(drone, i) in drones">
          <div class="camera" 
               draggable="true" 
               v-on:dragstart="drag(drone, drone.rgb_url, 'rgb', 'drone')" 
               v-on:touchstart="drag(drone, drone.rgb_url, 'rgb', 'drone')"
               v-on:touchmove="touchMove"
               v-on:touchend="touchEnd"
               :key="'rgb-'+i" 
               :title="`${drone.name} ${$t('page.monitor.cameraList.rgb')}`">
               {{drone.name}} {{ $t('page.monitor.cameraList.rgb') }}
          </div>
          <div class="camera"
               draggable="true"
               v-on:dragstart="drag(drone, drone.thermal_url, 'thermal', 'drone')"
               v-on:touchstart="drag(drone, drone.rgb_url, 'thermal', 'drone')"
               v-on:touchmove="touchMove"
               v-on:touchend="touchEnd"
               :key="'thermal'+i"
               :title="`${drone.name} ${$t('page.monitor.cameraList.thermal')}`">
               {{drone.name}} {{ $t('page.monitor.cameraList.thermal') }}
          </div>
        </template>
      </div>
    </div>

    <div class="category">
      <div class="title" v-on:click="toggleCollapse('ipCam')">
        <div :class="`triangle-clip ${(collapse.ipCam) ? 'collapse' : 'expand'}`"></div>
        {{ $t('map.ipCam.name') }}
      </div>
      <div class="category__content" v-show="!collapse.ipCam">
        <template v-for="(camera, i) in cameras">
          <div class="camera" 
               draggable="true" 
               v-on:dragstart="drag(camera, camera.rgb_url, 'rgb', 'camera')" 
               v-on:touchmove="touchMove"
               v-on:touchend="touchEnd"
               :key="'camera-'+i" 
               :title="`${camera.name}`">
               {{camera.name}}
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'CameraList',
    data() {
      return {
        collapse: {
          drone: false,
          ipCam: false,
        }
      };
    },
    props: {
      drones: {
        required: false,
        type: Array,
      },
      cameras: {
        required: false,
        type: Array,
      },
    },
    methods: {
      drag(device, url, type, deviceType) {
        this.$emit("dragstart", {device, deviceType, url, type});
      },
      touchMove(event) {
        // prevent scroll
        event.preventDefault();
      },
      touchEnd(event) {
        let dropPosition = {
          x: event.changedTouches[0].pageX,
          y: event.changedTouches[0].pageY,
        };

        let playbacks = document.querySelectorAll(".playback__wrapper");

        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        for (let playback of playbacks) {
          let rect = playback.getBoundingClientRect();
          if (dropPosition.x >= rect.x && 
            dropPosition.x <= rect.x + rect.width &&
            dropPosition.y >= rect.y &&
            dropPosition.y <= rect.y + rect.height) {
              let index = playback.getAttribute("data-index")
              this.$emit("drop", {index});
              return;
          }
        }
      },

      toggleCollapse(menu) {
        if (this.collapse[menu]) {
          this.collapse[menu] = false;
        } else {
          this.collapse[menu] = true;
        }
      },
    },
  };
</script>

<style lang="scss">
.camera-list {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: #252525;
  overflow: auto;

  .title {
    position: relative;
    .triangle-clip {
      position: absolute;
      left: 8px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      transform: rotate(180deg);
      width: 8px;
      height: 4.7px;
      background-color: white;
    }

    .triangle-clip.collapse {
      transform: rotate(90deg);
    }
  }

  .title,  .category__content > .camera {
    padding-left: 8px;
    background-color: #3B3B3B;
    color: rgba(255, 255, 255, 0.9);
    height: 30px;
    line-height: 30px;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #616161;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    cursor: grab;
  }

  .title,  .category__content > .camera:active {
    cursor: grabbing;
  }


  .category {
    .title {
      color: #92d6ff;
      padding-left: 24px;
      cursor: pointer;
    }
  }

  .category__content > .camera {
    background-color: #252525;
    height: 46px;
    line-height: 46px;
    padding-left: 24px;
  }

}
</style>