<template>
  <flight-map
    v-if="init || activate"
    @map-click="mapClick"
  >
    <!-- v-if is workaround -->
    <template v-for="(drone, index) in drones">
      <div
        v-if="activateDrone[index]"
        :key="`drone-${index}`"
        class="drone-items"
      >
        <multiple-point
          :read-only="true"
          source="Monitor-Drone"
          type="drone"
          :z-index="100 + index * 3 + 2"
          :value="dronePoint[index]"
        />

        <fly-route
          v-if="droneMission[index]"
          :id="drone.id"
          :name="drone.name"
          :task="droneMission[index].tasks"
          :history="droneMqHistory[index].gps"
          :edit-mode="false"
          :use-normal-icon="false"
          :z-index="100 + index * 3"
        />
      </div>
    </template>
    <slot />

    <vl-layer-vector :z-index="1000">
      <vl-source-vector
        ref="popupLineSource"
        ident="Overlay-Source"
        :features="popupLineFeature"
      />
      <vl-style-func :factory="popupLineStyle" />
    </vl-layer-vector>
    <!-- move this point has problem -->
    <multiple-point
      v-if="popupIcon && popupPositionOl"
      :read-only="true"
      source="Monitor-Popup"
      type="popup"
      :z-index="1001"
      :value="{list: [{ icon: popupIcon, coordinate: popupPositionOl}]}"
      @input="pointInput"
    />

    <vl-overlay
      v-if="popupPositionOl"
      :position="popupPositionOl"
      positioning="top-center"
    >
      <div class="fms-overlay-container">
        <slot name="popup" />
      </div>
    </vl-overlay>
  </flight-map>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GeographicApiToOl, GeographicXyToOl, GeographicOlToApi, GoogleMapsMarkerToOl, FormatGoogleDashToOl,
} from '@/utils/map.js';

import FlightMap from '@/components/OpenLayers/FlightMap.vue';
import MultiplePoint from '@/components/OpenLayers/MultiplePoint.vue';
import FlyRoute from '@/components/OpenLayers/FlyRoute.vue';
import { DroneStyle, DroneStyleImage, Icon } from '@/components/GoogleMaps/MarkerStyle.js';
import PolylineStyle from '@/components/GoogleMaps/PolylineStyle.js';

export default {
  name: 'MonitorMapByOpenLayers',
  components: {
    FlightMap,
    MultiplePoint,
    FlyRoute,
  },
  props: {
    selectDrone: {
      required: true,
    },
    mapOption: {
      required: true,
      type: Object,
    },
    drones: {
      required: true,
      type: Array,
    },
    activateDrone: {
      required: true,
      type: Array,
    },
    droneMq: {
      required: true,
      type: Array,
    },
    droneMqHistory: {
      required: true,
      type: Array,
    },
    droneStatus: {
      required: true,
      type: Array,
    },
    droneMission: {
      required: true,
      type: Array,
    },
    droneGuide: {
      required: true,
      type: Array,
    },
    popupPosition: {},
    popupIcon: {},
    popupPolyline: {},
    popupPolylineOption: {
      default() {
        return PolylineStyle.clickAndGoPolylineOptions;
      },
    },
  },
  data() {
    return {
      init: false,
    };
  },
  computed: {
    dronePoint() {
      return this.drones.map((drone, index) => {
        const activate = this.selectDrone && this.selectDrone.id === drone.id;
        const droneStatus = this.droneStatus[index].simple;
        const iconSet = DroneStyleImage[droneStatus];
        const coordinate = GeographicApiToOl(this.droneMq[index].gps);
        const points = {
          id: drone.id,
          name: drone.name,
          list: [],
        };
        if (this.droneGuide[index] != null) {
          points.list.push({
            icon: this.guideIcon,
            coordinate: GeographicApiToOl(this.droneGuide[index]),
          });
        }
        points.list = points.list.concat([
          {
            icon: DroneStyle.base[(activate) ? 'activate' : 'normal'],
            coordinate,
          },
          {
            icon: {
              ...iconSet,
              rotation: this.droneMq[index].attitude.yaw,
            },
            coordinate,
          },
        ]);
        return points;
      });
    },
    activate() {
      if (this.$route.query.tab === 'map') {
        this.init = true;
        return true;
      }
      return false;
    },
    popupPositionOl() {
      if (this.popupPosition == null) {
        return null;
      }
      return GeographicApiToOl(this.popupPosition);
    },
    guideIcon() {
      return Icon.clickAndGo;
    },
    popupIconOl() {
      if (this.popupIcon != null) {
        return GoogleMapsMarkerToOl(this.popupIcon);
      }
      return null;
    },
    popupLineFeature() {
      const features = [];
      if (this.popupPolyline != null) {
        features.push({
          type: 'Feature',
          id: 'PopupString',
          geometry: {
            type: 'LineString',
            coordinates: this.popupPolyline.map(GeographicApiToOl),
          },
          properties: {
            source: 'popup',
            type: 'line-string',
          },
        });
      }
      return features;
    },
  },
  watch: {
  },
  methods: {
    mapClick(event, featureAndLayerList) {
      const clickEvent = {
        position: GeographicOlToApi(event.coordinate),
      };

      const onDrone = featureAndLayerList.find((f) => f.properties.source === 'Monitor-Drone'
          && f.properties.type === 'drone');
      if (onDrone != null) {
        const droneIndex = this.drones.findIndex((drone) => drone.id == onDrone.properties.id);
        clickEvent.source = 'drone';
        clickEvent.index = droneIndex;
        this.$emit('map-click', clickEvent);
        return;
      }

      const onFence = featureAndLayerList.find((f) => f.properties.source === 'fence'
          && f.properties.type === 'polygon');
      if (onFence) {
        clickEvent.source = 'fence';
        this.$emit('map-click', clickEvent);
      }
    },
    pointInput(path) {
      const position = GeographicOlToApi(path.list['0'].coordinate);
      if (position.latitude === this.popupPosition.latitude
      && position.longitude === this.popupPosition.longitude) {
        return;
      }
      this.$emit('update:popupPosition', popupPosition);
    },
    popupLineStyle() {
      return (feature) => {
        if (this.popupPolylineOption.icons != null) {
          return FormatGoogleDashToOl(this.popupPolylineOption);
        }
        return FormatGoogleLineToOl(this.popupPolylineOption);
      };
    },
  },
};
</script>
