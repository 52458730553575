<template>
  <flight-map
    ref="mapRef"
    :edit-mode="false"
  >
    <fly-route :task="mission.tasks"
                :id="mission.id"
                :name="mission.name"
                ref="path"
                :edit-mode="false"/>
  </flight-map>
</template>

<script>
import FlightMap from '@/components/OpenLayers/FlightMap.vue';
import FlyRoute from '@/components/OpenLayers/FlyRoute.vue';

export default {
  name: 'AssignMissionMap-OpenLayers',
  components: {
    FlightMap,
    FlyRoute,
  },
  props: {
    mission: {
      type: Object,
      required: true,
    },
  },
};
</script>