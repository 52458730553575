<template>
  <div class="player-container">

	  <iframe class="iframe-player"
          :src="`/players/videojs_player.html?streaming=http://${urlHost}:${urlPort}/${urlStreamApp}/flv:${urlStream}/playlist.m3u8`" />

    <!-- fix drop to iframe can't catch by drop event -->
    <div class="drop-area" :style="(dragging) ? '' : 'display: none;'">
      <!-- <span>Drag Camera Here</span> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HtmlRtmpPlayer',
    props: {
      url: {
        required: true,
        type: Object,
      },
      dragging: {
        required: false,
        default: false,
      },
    },
    methods: {
      allowDrop(event) {
        event.preventDefault();
      },
      drop(index) {
        this.$emit("drop", {index});
      },
	},
	async mounted() {
		console.log("html rtmp player vue loaded");
		console.log("Videojs detect loading");
	},
    computed: {
      urlProtocolEnd() {
        return this.url.rtmp.toUpperCase().indexOf('://') + '://'.length;
      },
      urlServerLength() {
        return this.url.rtmp.substring(this.urlProtocolEnd).indexOf('/');
      },
      urlStreamAppLength() {
        return this.url.rtmp.substring(this.urlProtocolEnd + this.urlServerLength + 1).indexOf('/');
      },
      urlServer() {
        return this.url.rtmp.substring(this.urlProtocolEnd, this.urlServerLength + this.urlProtocolEnd);
      },
      urlHost() {
        return this.urlServer.split(':')[0]
      },
      urlPort() {
        let port = this.urlServer.split(':')[1]
        if (port == null) {
          return 1935;
        } else {
          return parseInt(port);
        }
      },
      urlStreamApp() {
        return this.url.rtmp.substring(
          this.urlProtocolEnd + this.urlServerLength + 1,
          this.urlProtocolEnd + this.urlServerLength + 1 + this.urlStreamAppLength
        );
      },
      urlStream() {
        return this.url.rtmp.substring(
          this.urlProtocolEnd + this.urlServerLength + 1 + this.urlStreamAppLength + 1
        );
      },
    }
  };
</script>

<style lang="scss">
.player-container {
  width: 100%;
  height: 100%;
}
.drop-area {
  // background-color: rgba(0, 0, 0, 0.3);
  // color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.video-js {
  border: 0;
  width: 100%;
  height: 100%;
}
</style>