<template>
  <el-dropdown trigger="click" :hide-on-click="false" @command="handleCommand" ref="dropdown" class="drone-dropdown-list">
    <button class="image-button drone-button">{{$t('page.monitor.cameraList.drones')}} <img src="/asserts/icon/drone-dropdown-icon.svg"></button>
    <el-dropdown-menu slot="dropdown" class="drone-dropdown-list__content">
      <el-dropdown-item v-for="(drone, index) in drones" :key="`drones-${index}`" :command="index">
        <span class="name">{{ drone.name }}</span>
        <button :class="{'image-button': true, 'activate' : (activateDrone[index]) }" ></button>
      </el-dropdown-item>
      <section class="footer">
        <el-button size="mini" type="primary" @click="save">{{ $t('button.done') }}</el-button>
      </section>
    </el-dropdown-menu>
  </el-dropdown>

</template>

<script>
import { CopyObject } from '@/utils/common.js';
import { mapGetters } from 'vuex';

export default {
  name: 'DroneDropdown',
  components: {
  },
  computed: {
    isInitGroup(){
      return this.drones.length && !this.value.length;
    },
    ...mapGetters({
      group: 'user/group',
    }),
  },
  data() {
    return {
      activateDrone: [],
    };
  },
  created() {
    if (this.isInitGroup) {
      this.$emit('input', this.drones.map(() => true));
    }
  },
  mounted() {
    this.$set(this, 'activateDrone', CopyObject(this.value));
  },
  destroyed() {
  },
  methods: {
    handleCommand(index) {
      this.$set(this.activateDrone, index, !this.activateDrone[index]);
    },
    save() {
      this.$emit('input', this.activateDrone);
      // reference: https://github.com/ElemeFE/element/blob/f6df39e8c1ff390da0f0df8ea30b07baf5d457f0/packages/dropdown/src/dropdown.vue#L171
      this.$refs.dropdown.visible = false;
    },
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    drones: {
      required: true,
      type: Array,
    },
  },
  watch: {
    value: {
      handler() {
        this.$set(this, 'activateDrone', CopyObject(this.value));
      },
    },
    drones:{
      handler(){
        if(this.isInitGroup){
          this.$emit('input', this.drones.map(() => true));
        }
      },
      deep: true,
    }
  },
}
</script>

<style lang="scss">
.drone-dropdown-list {

  .drone-button {
    font-size: 12px;
  }
}

.drone-dropdown-list__content {
  padding-top: 0;
  padding-bottom: 5px;
  width: 180px;

  .el-dropdown-menu__item:not(.title) {
    height: 35px;
    color: #858585;
  }

  .el-dropdown-menu__item {
    width: 100%;
    line-height: initial;
    font-size: 12px;
    display: table;
    position: relative;
    padding-left: 8px;
    padding-right: 18px;
    border-bottom: 1px solid rgba(191, 191, 191, 0.6);
    position: relative;

    span {
      display: table-cell;
      vertical-align: middle;
    }

    button {
      background-image: url('/asserts/icon/mapview-invisible-icon.svg');
      background-repeat: no-repeat;

      background-position: 0 50%;
      position: absolute;
      height: 11px;
      width: 16px;
      right: 18px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    button.activate {
      background-image: url('/asserts/icon/mapview-visible-icon.svg');
    }
  }

  .popper__arrow::after {
    // border-bottom-color: #203547 !important;
  }

  .footer {
    margin-top: 6.5px;
    text-align: center;
    .el-button.el-button--mini {
      font-size: 14px;
      height: 24px;
    }
  }
}

</style>
