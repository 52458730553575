<template>
  <div
    v-show="drones.length > 0"
    class="map-bottom drone-select"
  >
    <button
      class="drone-select__navi image-button pervious"
      :disabled="droneSelectPage <= 0"
      @click="droneSelectPage -= 1"
    />
    <div class="drone-select__container">
      <div
        ref="droneSelectContent"
        class="drone-select__content"
        :style="droneSelectStyle"
      >
        <div
          v-for="(drone, index) in drones"
          :key="`drone-${index}`"
          class="drone"
          @click="$emit('controllDrone', index)"
        >
          <div class="drone-expend" />
          <div class="drone-name">
            <img src="/asserts/icon/mapview-dronestatus-icon.svg">
            <span class="drone-name__content">{{ drone.name }}</span>
          </div>
          <div class="drone-status-light">
            <div :class="`light ${statusList[index].detail}`" />
            <span class="drone-status">{{ $t(`page.monitor.status.${statusList[index].detail}`) }}</span>
          </div>
          <div class="drone-speed-battery">
            <div class="drone-speed">
              <label>{{ $t(`page.monitor.speed`) }}</label>
              <span v-if="droneMq[index].hud.ground_speed != null"> {{ getTransSpeed(droneMq[index].hud.ground_speed) | roundNumber(1) }}{{ speedUnitHint }}</span>
              <span v-else> --</span>
            </div>
            <div class="drone-battery">
              <img src="/asserts/icon/dronelist-battery-icon.svg">
              <span v-if="droneMq[index].battery_status.remaining != null"> {{ droneMq[index].battery_status.remaining }}%</span>
              <span v-else> --</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="drone-select__navi image-button next"
      :disabled="droneSelectPage >= droneMaxPage"
      @click="droneSelectPage += 1"
    />
  </div>
</template>

<script>
import { SpeedMeasure } from '@/utils/measure';

export default {
  name: 'DroneSelector',
  props: {
    drones: {
      required: true,
      type: Array,
    },
    droneMq: {
      required: true,
      type: Array,
    },
    statusList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      droneSelectPage: 0,
      droneSelectWidth: 0,
      // .drone style: width 200px + margin-right 19px
      droneSingleWidth: 219,
    };
  },
  computed: {
    dronePerPage() {
      return Math.floor(this.droneSelectWidth / this.droneSingleWidth);
    },
    droneMaxPage() {
      return Math.ceil(this.drones.length / this.dronePerPage) - 1;
    },
    droneSelectStyle() {
      return {
        'margin-left': `${this.droneSelectPage * this.dronePerPage * this.droneSingleWidth * -1}px`,
      };
    },
    speedUnitHint() {
      return SpeedMeasure.unit();
    },
  },
  watch: {
    drones(now, old) {
      this.$nextTick(() => {
        this.getDroneListWidth();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getDroneListWidth();
    });
    window.addEventListener('resize', this.getDroneListWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.getDroneListWidth);
  },
  methods: {
    getTransSpeed(value, digit = 1) {
      return SpeedMeasure.display(value, digit, 0);
    },
    getDroneListWidth() {
      this.droneSelectWidth = this.$refs.droneSelectContent.offsetWidth;
    },
  },
};
</script>

<style lang="scss">
.drone-select {
  height: 76px;
  overflow: hidden;
  white-space: nowrap;

  .drone-select__container {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }

  .drone-select__content {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    position: absolute;
    left: 0;
    right: 0;

    // .drone-status-light {

    // }
  }

  .drone-select__navi {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 30px;
    height: 30px;
  }

  .drone-select__navi.pervious {
    left: 12px;
    background-image: url('/asserts/icon/dronelist-icon-previous.svg');
  }

  .drone-select__navi.next {
    right: 12px;
    background-image: url('/asserts/icon/dronelist-icon-back-next.svg');
  }

  .drone {
    display: inline-block;
    background-color: white;
    width: 200px;
    height: 76px;
    border-radius: 4px 4px 0 0;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.5);
    margin-right: 19px;
    margin-bottom: 12px; // shadow 6px + 6px

    .drone-expend {
      padding-top: 5.5px;
      height: 17px;
    }

    .drone-name {
      height: 20px;
      font-size: 14px;
      > * {
        float: left;
      }

      > img {
        margin-left: 7px;
        margin-right: 5px;
      }

      > .drone-name__content {
        font-size: 14px;
        font-weight: bold;
        margin-top: 2px;
      }

    }
    .drone-speed-battery {
      margin-top: 2px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 16px;
      label {
        font-size: 14px;
      }
      .drone-battery {
        margin-left: 10px;
      }
    }
    // .drone-speed {
    //   text-align: center;
    //   margin-top: 4px;
    //   height: 35px;
    //   font-size: 22px;
    //   > label {
    //     font-size: 18px;
    //   }
    // }
  }

  .drone:hover {
    background-color: #e5e5e5;

    .drone-expend {
      background-image: url('/asserts/icon/mapview-controldrone-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

</style>
