<template>
  <div class="playback">
    <div
      ref="wrapper"
      :class="{playback__wrapper: true, 'activate' : (url), 'fullscreen' : (isFullscreen)}"
      :data-index="index"
      @drop="drop(index)"
      @dragover="allowDrop"
    >
      <div class="playback__content placeholder">
        <div
          v-if="!info"
          class="placeholder__content"
        >
          <div class="placeholder__center">
            <!-- <div class="box"></div> -->
            <img src="/asserts/icon/liveview-drogcamera-icon.svg">
            <p>{{ $t('page.monitor.playback.dragHere') }}</p>
          </div>
        </div>
        <div
          v-else-if="!url"
          class="placeholder__content"
        >
          <div class="placeholder__center">
            <img src="/asserts/icon/liveview-noresultsfound-icon.svg">
          </div>
        </div>
      </div>
      <div class="playback__content video">
        <!-- <html-rtmp-player :url="url" v-if="url" :dragging="dragging"></html-rtmp-player> -->
        <flash-rtmp-player
          v-if="url"
          :url="url"
          :dragging="dragging"
        />
        <!-- <native-file-player :url="url" v-if="url"></native-file-player> -->
      </div>
      <div class="playback__content info">
        <p>
          <template v-if="info && info.device">
            <span
              class="mission"
              :title="(info.device.mission) ? info.device.mission.name : '' "
            >{{ (info.device.mission) ? info.device.mission.name : '' }}</span>
            <span
              class="drone"
              :title="info.device.name"
            >{{ info.device.name }}</span>
            <span class="type">{{ info.type }}</span>
          </template>
        </p>
      </div>
      <img
        class="icon-reset"
        src="/asserts/icon/liveview-reset-icon.svg"
        @click="reset"
      >
      <img
        v-show="!isFullscreen"
        class="icon-fullscreen"
        src="/asserts/icon/liveview-fullscreen-icon.svg"
        @click="fullscreen"
      >
      <img
        v-show="isFullscreen"
        class="icon-fullscreen"
        src="/asserts/icon/liveview-fullscreen-close-icon.svg"
        @click="exitFullscreen"
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HtmlRtmpPlayer from '@/components/Monitor/HtmlRtmpPlayer.vue';
import FlashRtmpPlayer from '@/components/Monitor/FlashRtmpPlayer.vue';
import NativeFilePlayer from '@/components/Monitor/NativeFilePlayer.vue';
import { logger } from '@/logger/index';
import {
  FullscreenElement, ExitFullscreen, ListenFullscreenChange, RemoveListenFullscreenChange, CheckFullscreenElement,
} from '@/utils/fullscreen.js';
import droneApi from '@/services/api/domain/group/drone';

export default {
  name: 'Playback',
  components: {
    FlashRtmpPlayer,
	  NativeFilePlayer,
	  HtmlRtmpPlayer,
  },
  props: {
    index: {
      required: true,
    },
    url: {
      required: false,
      type: Object,
      default: null,
    },
    info: {
      required: false,
      default: null,
    },
    dragging: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isFullscreen: false,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
    }),
  },
  mounted() {
    if (navigator.userAgent.indexOf('iPad') >= 0) {
      const ipadCssNode = document.createElement('style');
      ipadCssNode.innerHTML = '.playback__wrapper.fullscreen { top: 20px }';
      document.body.appendChild(ipadCssNode);
    }
    ListenFullscreenChange(this.fullscreenChange);
  },
  destroyed() {
    RemoveListenFullscreenChange(this.fullscreenChange);
  },
  methods: {
    allowDrop(event) {
      event.preventDefault();
    },
    drop(index) {
      this.$emit('drop', { index });
    },
    fullscreen() {
      const fullscreenElement = this.$refs.wrapper;
      this.isFullscreen = true;
      FullscreenElement(fullscreenElement);
    },
    exitFullscreen() {
      ExitFullscreen();
      this.isFullscreen = false;
    },
    fullscreenChange(event) {
      if (CheckFullscreenElement() == null) {
        this.isFullscreen = false;
      }
    },
    async reset() {
      logger.debug('reset stream');
      const loading = this.$loading({
        lock: true,
      });
      try {
        const status = await droneApi.execute(this.group.id, this.info.device.id, 'stream_reset', {});
        this.$showInfo(this.$t('page.monitor.control.stream_reset'));
      } catch (error) {
        this.$showFail(error);
      }
      loading.close();
    },
  },
};
</script>

<style lang="scss">
  .playback__content.info {
    width: 280px;
    height: 26px;
    -webkit-clip-path: polygon(0 0, 280px 0%, 265px 100%, 0% 100%);
    clip-path: polygon(0 0, 280px 0%, 265px 100%, 0% 100%);
    span .mission{
      font-weight: bold;
      float: left;
      width: 128px;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 10px;
      text-overflow: ellipsis;
    }
    .mission {
      font-weight: bold;
      float: left;
      max-width: 128px;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 10px;
      text-overflow: ellipsis;
    }
    .drone {
      float: left;
      max-width: 70px;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 10px;
      text-overflow: ellipsis;
    }
    .type{
      float: initial;
    }
    background-color: #E9E9E9;
    p {
      font-size: 12px;
      margin: 0px 10px 0px 10px;
      line-height: 26px;
    }
  }
  .playback__wrapper {
    background-color: black;
    color: white;
    .icon-fullscreen {
      position: absolute;
      left: auto;
      right: 10px;
      top: 8px;
      cursor: pointer;
    }
    .icon-reset {
      position: absolute;
      left: auto;
      right: 43px;
      top: 8px;
      cursor: pointer;
    }
  }
  .playback__wrapper.fullscreen {
    border: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 26656;
  }
  .playback__content.video {
    bottom: 0;
    right: 0;
    // follow for debug
    display: hide;
  }
  .playback__content.placeholder {
    color: #848484;
    bottom: 0;
    right: 0;
    .placeholder__content {
      width: 100%;
      height: 100%;
      display: table;
      .placeholder__center {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        .box {
          width: 50px;
          height: 50px;
          background-color: red;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
</style>
