<template>
  <admin-main-layout>
    <template slot="toolbar">
      <el-row class="no-margin">
        <h1>{{ $t("page.mission.title") }}</h1>
      </el-row>
      <el-row
        type="flex"
        justify="end"
      >
        <img
          class="mission-select"
          src="/asserts/icon/missions-import-mission-icon.svg"
          :disabled="!isAdmin"
          @click="importDialogShow"
        ><div
          :disabled="!isAdmin"
          class="section-label mission-pad mission-select"
          @click="importDialogShow"
        >
          {{ $t("button.import") }}
        </div>
        <img
          class="mission-select"
          src="/asserts/icon/missions-export-mission-icon.svg"
          :disabled="!isAdmin"
          @click="exportDialogShow"
        ><div
          :disabled="!isAdmin"
          class="section-label mission-pad mission-select"
          @click="exportDialogShow"
        >
          {{ $t("button.export") }}
        </div>
        <el-button
          v-if="source.items.length > 0"
          type="primary"
          :disabled="!isAdmin"
          class="main-actions"
          @click="dialogOpen()"
        >
          <fa-icon icon="plus" /> {{ $t("button.add") }}
        </el-button>
      </el-row>
    </template>

    <div
      v-if="page.loading == false && source.items.length <= 0"
      class="no-mission"
    >
      <div class="no-mission__content">
        <h3>{{ $t("page.mission.noMission.title") }}</h3>
        <p>{{ $t("page.mission.noMission.content") }}</p>
        <el-button
          type="primary"
          :disabled="!isAdmin"
          class="main-actions"
          @click="dialogOpen()"
        >
          <fa-icon icon="plus" /> {{ $t("button.add") }}
        </el-button>
      </div>
    </div>

    <export-mission-dialog ref="exportMission" />

    <import-mission-dialog ref="importMission" />

    <data-table
      v-show="source.items.length > 0"
      :data="source.items"
      :total="source.paging.total"
      :loading="page.loading"
      :config="page.tableProps"
      :show-tool-bar="false"
      @query-change="loadData"
    >
      <el-table-column
        v-for="columnProp in page.tableProps.tableColumnProps"
        :key="columnProp.prop"
        :type="columnProp.type"
        :label="$t(columnProp.label)"
        :column-key="columnProp.columnKey"
        :prop="columnProp.prop"
        :width="columnProp.width"
        :min-width="columnProp.minWidth"
        :sortable="columnProp.sortable"
        :resizable="columnProp.resizable"
        :formatter="columnProp.formatter"
        :align="columnProp.align"
        :header-align="columnProp.headerAlign"
        :class-name="columnProp.className"
        :label-class-name="columnProp.labelClassName"
      >
        <template scope="scope">
          <div
            v-if="columnProp.prop == 'mapUrl'"
            class="ima-cell"
          >
            <data-table-thumbnail
              :class="{ thumbnail: true, invalid: !scope.row.valid }"
              :value="scope.row"
            />
          </div>
          <div v-else-if="columnProp.prop == 'end_time'">
            {{ scope.row.create_time | formatDateTime($t("formatter.dateTime")) }}
          </div>
          <div v-else-if="columnProp.prop == 'period'">
            {{ FormatMissionPeriod(scope.row.period) }}
          </div>
          <div v-else>
            {{ scope.row[columnProp.prop] }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label=""
        width="120px"
      >
        <template scope="scope">
          <!-- <el-button-group> -->
          <fa-button
            size="mini"
            :no-border="true"
            icon=""
            class="action-button edit"
            @click="dialogOpen(scope.row.id)"
          />

          <fa-button
            size="mini"
            :no-border="true"
            icon=""
            class="action-button load"
            @click="loadMission(scope.row)"
          />
          <!-- </el-button-group> -->
        </template>
      </el-table-column>
    </data-table>

    <default-dialog
      :show-dialog="missionShow"
      :title="missionMode == 'add' ?
        $t('page.mission.dialog.addTitle') : $t('page.mission.dialog.updateTitle')"
      :next-button-text="dialogBtnTxt"
      :footer-align="missionMode == 'update' ? 'right' : 'center'"
      @close="dialogClose"
      @next="dialogSave"
    >
      <template slot="main">
        <el-form
          ref="form"
          :model="mission"
          label-width="120px"
          :rules="checkMission"
        >
          <el-form-item
            :label="$t('page.mission.dialog.name')"
            prop="name"
            class="with_error_hint"
          >
            <el-input
              v-model="mission.name"
              class="main-input"
            />
          </el-form-item>
          <el-form-item :label="$t('page.mission.dialog.type')">
            <el-select
              v-model="mission.type"
              class="main-input"
            >
              <el-option
                v-if="missionMode == 'add' || mission.type == 'line'"
                :label="$t('page.mission.dialog.types.lineString')"
                value="line"
              />
              <el-option
                v-if="missionMode == 'add' || mission.type == 'counting'"
                :label="$t('page.mission.dialog.types.lineCounting')"
                value="counting"
              />
              <template v-if="missionMode == 'add'">
                <el-option
                  :label="$t('page.mission.dialog.types.aerialSingle')"
                  value="aerialSingle"
                />
                <el-option
                  :label="$t('page.mission.dialog.types.aerialGrid')"
                  value="aerialGrid"
                />
              </template>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="Schedule">
            <el-switch v-model="mission.schedule"></el-switch>
          </el-form-item> -->
        </el-form>
        <el-form
          class="schedule"
          :model="mission"
          label-width="100px"
          :inline="true"
        >
          <el-form-item :label="$t('page.mission.dialog.startTime')">
            <date-time-picker
              v-model="mission.start_time"
              :hover-show="false"
            />
          </el-form-item>
          <el-form-item :label="$t('page.mission.dialog.endTime')">
            <date-time-picker
              v-model="mission.end_time"
              :hover-show="false"
            />
          </el-form-item>
        </el-form>
        <el-form
          class="schedule"
          :model="mission"
          label-width="100px"
          :inline="true"
        >
          <el-form-item :label="$t('page.mission.dialog.repeat')">
            <el-checkbox
              v-model="mission.noRepeat"
              @change="(event) => {if (event) mission.period = 1; else mission.period = 0}"
            >
              {{ $t("page.mission.dialog.noRepeat") }}
            </el-checkbox>
          </el-form-item>

          <el-form-item :label="$t('page.mission.dialog.period')">
            <div class="period-group">
              <el-input-number
                v-model="mission.period"
                controls-position="right"
                :min="1"
                :disabled="mission.noRepeat"
              />
              <el-select
                v-model="mission.periodUnit"
                :disabled="mission.noRepeat"
              >
                <el-option
                  v-for="(item, index) in periodUnit"
                  :key="index"
                  :label="$t(item.name)"
                  :value="item.magnification"
                />
              </el-select>
            </div>
            <!-- <el-switch v-model="mission.period"></el-switch> -->
          </el-form-item>
        </el-form>
      </template>
      <template slot="footer">
        <el-button
          v-if="missionMode == 'update'"
          :disabled="!isAdmin"
          class="main-actions right-button"
          type="warning"
          @click="deleteShow = true"
        >
          {{ $t("button.delete") }}
        </el-button>
      </template>
    </default-dialog>

    <default-dialog
      :show-dialog="deleteShow"
      :center="true"
      :body-center="true"
      :next-button-text="$t('page.mission.delete')"
      @close="deleteClose"
      @next="deleteAction"
    >
      <template slot="main">
        <h2>{{ $t("page.mission.deleteConfirm.title") }}</h2>
        <p>{{ $t("page.mission.deleteConfirm.body") }}</p>
      </template>
    </default-dialog>

    <assign-mission-dialog
      ref="assignMission"
      :pre-select-mission="assignMission"
    />
  </admin-main-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import { FormatMissionPeriod } from '@/utils/mission';
import { logger } from '@/logger/index';
import DataTable from '@/components/DataTable/DataTable';
import FaButton from '@/components/FaButton';
import DateTimePicker from '@/components/DateTimePicker.vue';
import MissionApi from '@/services/api/domain/group/mission';
import tableProps from '@/config/tableProps/missionList';
import DefaultDialog from '@/components/DefaultDialog.vue';
import AssignMissionDialog from '@/components/AssignMissionDialog.vue';
import ExportMissionDialog from '@/components/ExportMissionDialog.vue';
import ImportMissionDialog from '@/components/ImportMissionDialog.vue';
import DataTableThumbnail from '@/components/DataTable/Thumbnail.vue';

export default {
  name: 'MissionList',
  components: {
    DateTimePicker,
    DataTable,
    FaButton,
    DefaultDialog,
    AssignMissionDialog,
    DataTableThumbnail,
    ExportMissionDialog,
    ImportMissionDialog,
  },
  data() {
    return {
      googleMapKey: this.$config.googleMapKey,
      deleteShow: false,
      missionId: null,
      source: {
        items: [],
        paging: {
          total: 0,
        },
      },
      filter: {},
      page: {
        loading: false,
        selectedItems: [],
        tableProps,
      },
      mission: CopyObject(this.$store.state.mission.mission),
      periodUnit: this.$store.state.mission.periodUnit,
      assignMission: {},
      loadDataNonce: null,
      checkMission: {
        name: [
          { validator: this.missionNameValid, trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    dialogBtnTxt() {
      let text = this.$t('page.mission.dialog.addWaypoint');
      if (this.missionMode !== 'add') {
        text = this.$t('page.mission.dialog.editWaypoint');
      } else if (this.mission.type !== 'line' && this.mission.type !== 'counting') {
        text = this.$t('button.next');
      }
      return text;
    },
    missionsName() {
      return this.source.items.map((group) => group.name);
    },
    ...mapGetters({
      group: 'user/group',
      groupId: 'user/groupId',
      missionMode: 'mission/getMode',
      missionData: 'mission/getMission',
      missionShow: 'mission/shouldShow',
      isAdmin: 'user/isAdmin',
    }),
  },
  created() {
    if (this.$router.currentRoute.params.clear) {
      this.setMissionMode(null);
    }
  },
  methods: {
    async loadData(filter) {
      const localNonce = (this.loadDataNonce = new Object());
      // pageation config
      // this.filter.page = filter.page;
      // this.filter.limit = filter.pageSize;

      if (filter && filter.sort != null && filter.sort.prop != null) {
        this.filter.sort = `${filter.sort.prop}${filter.sort.order == 'descending' ? ':des' : ''}`;
      } else {
        this.filter.sort = null;
      }

      try {
        this.page.loading = true;
        const { meta, data } = await MissionApi.getAll(this.groupId, this.filter);
        if (localNonce !== this.loadDataNonce) {
          // a new call was made
          return;
        }
        this.source.items = data.missions;
        this.page.loading = false;
      } catch (error) {
        this.$showFail(error);
        this.page.loading = false;
        // } finally {
        //   this.page.loading = false;
      }
    },
    async dialogOpen(missionId) {
      if (missionId) {
        this.missionId = missionId;
        try {
          this.page.loading = true;
          const { data } = await MissionApi.get(this.groupId, missionId);
          data.type = data.tasks.find((task) => task.command === 'do_counting_control') ? 'counting' : 'line';
          this.importMission(data);
          this.mission = CopyObject(this.$store.state.mission.mission);
          this.setMissionMode('update');
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.setMissionMode('add');
      }
    },
    dialogClose() {
      // confirm data will lost?
      // this.dialog.show = false;
      this.setMissionMode(null);
      this.mission = CopyObject(this.$store.state.mission.mission);
      this.$refs.form.clearValidate();
    },
    async dialogSave() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        /** 驗證沒通過 */
        return;
      }
      this.setMission(this.mission);
      const arialTypes = ['aerialSingle', 'aerialGrid'];

      if (arialTypes.indexOf(this.mission.type) >= 0) {
        if (this.mission.type === 'aerialSingle') {
          this.mission.areaSurveyConfig.type = 0;
        } else {
          this.mission.areaSurveyConfig.type = 1;
        }
        this.$router.push({
          name: 'missionAerialSurveySetArea',
        });
      } else if (this.missionMode === 'add') {
        this.$router.push({
          name: `missionCreateMap${this.mission.type === 'counting' ? 'WithCounting' : ''}`,
        });
      } else {
        this.$router.push({
          name: `missionEditMap${this.mission.type === 'counting' ? 'WithCounting' : ''}`,
        });
      }
    },
    deleteClose() {
      this.deleteShow = false;
    },
    async deleteAction() {
      try {
        this.deleteShow = false;
        this.dialogClose();
        this.page.loading = true;

        const status = await MissionApi.delete(this.groupId, this.missionId);
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadData();
    },
    loadMission(mission) {
      this.assignMission = CopyObject(mission);
      const _this = this;
      this.$nextTick(() => {
        this.$refs.assignMission.open();
      });
    },
    FormatMissionPeriod,
    ...mapActions({
      setMissionMode: 'mission/setMode',
      setMission: 'mission/setMission',
      importMission: 'mission/importMission',
    }),
    exportDialogShow() {
      this.$refs.exportMission.open();
    },
    importDialogShow() {
      this.$refs.importMission.open();
    },
    missionNameValid(rule, val, cb) {
      if (this.missionsName.includes(val) && this.missionData.name !== val) {
        cb(this.$t('page.mission.alert.nameExist'));
      } else if (val.length === 0) {
        cb(this.$t('page.mission.alert.nameEmpty'));
      } else {
        cb();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // 判斷網址來源，如果跟 mission 無關那就留下 params 告訴 created 要清掉現有任務
    const pathFirstLevel = from.path.split('/')[1];
    if (pathFirstLevel.toLocaleLowerCase() !== 'mission') {
      to.params.clear = true;
    } else {
      to.params.clear = false;
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.main-input {
  width: 236px;
}

.el-form--inline {
  .el-form-item {
    .el-form-item__content {
      width: 178px;
      .el-date-editor {
        width: 178px;
        .el-input__inner {
          padding-right: 0px;
        }
      }
    }
  }
}

.period-group {
  .el-input-number {
    // width: 95px;
  }
}

.el-dialog__wrapper.delete {
  .el-dialog {
    .el-dialog__header {
      padding: 0;
    }
  }
}

.action-button.load {
  background-image: url("/asserts/icon/missions-load-mission-icon-normal.svg");
  width: 48px;
}

.action-button.load:hover {
  background-image: url("/asserts/icon/missions-load-mission-icon-focus.svg");
}

.no-mission {
  height: 461px;
  border-style: dashed;
  border-color: #e1e1e1;
  border-radius: 13px;
  text-align: center;

  .no-mission__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  h3 {
    color: #a5a5a5;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 22px;
  }

  p {
    color: rgba(165, 165, 165, 0.9);
  }

  button {
    margin-top: 66px;
    margin-bottom: 66px;
  }
}

.mission-pad {
  margin-top: 14px;
  margin-left: 4px;
  margin-right: 16px;
}

.mission-select {
  cursor: grab;
}

.thumbnail {
  height: 84px;
  min-height: 84px;
  min-width: 124px;
  &.invalid {
    border: solid 2px #e0311f;
  }
  border: solid 1px #909399;
}
</style>
