<template>
  <flight-map
    ref="mapRef"
    :edit-mode="false"
  >
    <fly-router :task="mission.tasks"
                ref="path"
                :edit-mode="false"/>
  </flight-map>
</template>

<script>
import FlightMap from "@/components/GoogleMaps/FlightMap.vue";
import FlyRouter from '@/components/GoogleMaps/FlyRoute.vue'

export default {
  name: 'AssignMissionMap-GoogleMaps',
  components: {
    FlightMap,
    FlyRouter,
  },
  props: {
    mission: {
      type: Object,
      required: true,
    },
  },
};
</script>