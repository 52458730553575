<template>
  <video width='100%' height='100%' controls autoplay>
      <source :src="url.HTML" />
  </video>
    
</template>

<script>
  export default {
    name: 'NativeFilePlayer',
    props: {
      url: {
        required: true,
        type: Object,
      },
    },
    methods: {
      allowDrop(event) {
        event.preventDefault();
      },
      drop(index) {
        this.$emit("drop", {index});
      },
    },

  };
</script>

<style lang="scss">

</style>