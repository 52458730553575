export default {
  mainPolylineOptions: {
    strokeColor: '#4EC3F5',
    strokeOpacity: 1.0,
    strokeWeight: 3,
    clickable: false,
    zIndex: 1000,
  },
  donePolylineOptions: {
    strokeColor: '#b9b9b9',
    strokeOpacity: 1.0,
    strokeWeight: 3,
    clickable: false,
    zIndex: 1000,
  },
  returnHomePolylineOptions: {
    strokeOpacity: 0,
    icons: [{
      icon: {
        path: 'M 0,-1 0,1',
        strokeColor: '#4EC3F5',
        strokeOpacity: 1.0,
        scale: 3,
        // zIndex: 1000 + this.index * 10 + 1,
      },
      offset: '0',
      repeat: '15px',
    }],
    zIndex: 1000,
  },
  clickAndGoPolylineOptions: {
    strokeOpacity: 0,
    icons: [{
      icon: {
        path: 'M 0,-1 0,1',
        strokeColor: '#ffc900',
        strokeOpacity: 1.0,
        scale: 3,
        // zIndex: 1000 + this.index * 10 + 1,
      },
      offset: '0',
      repeat: '15px',
    }],
    zIndex: 1200,
  },
  historyPolylineOptions: {
    strokeColor: '#de9c00',
    strokeOpacity: 1.0,
    strokeWeight: 3,
    clickable: false,
    zIndex: 1001,
  },
};