<template>
  <el-row
    id="car-detection"
    class="car-detection"
  >
    <el-row
      type="flex"
      class="title"
    >
      <el-col
        class="title name"
        :span="9"
      >
        Object Detection
      </el-col>
      <el-col
        :span="6"
        type="flex"
        class="title selector"
        justify="center"
      >
        <el-radio-group v-model="detectionType">
          <el-radio-button label="Car" />
          <el-radio-button label="Person" />
        </el-radio-group>
      </el-col>
      <el-col
        class="title time"
        :span="9"
      >
        Last update:{{ dashboardTime ? dashboardTime.format("YYYY.M.D HH:mm") : '--' }}
      </el-col>
    </el-row>
    <el-row
      type="flex"
      justify="space-around"
      class="car-detection__dashboard"
    >
      <el-col
        class="dashboard"
        :span="7"
      >
        <el-col class="dashboard__container">
          <span
            class="dashboard__sign"
            :class="state"
          > {{ state }} </span>
        </el-col>
        <el-col class="dashboard__container">
          <span class="dashboard__main no-padding">{{ gaugeData }}</span>
        </el-col>
        <el-col class="dashboard__container">
          <span class="dashboard__sub">Number of {{ detectionType }}</span>
        </el-col>
      </el-col>
      <el-col
        class="gutter"
        :span="7"
      >
        <p
          class="gauge__number"
          :class="state"
        >
          {{ gaugeData }}
        </p>
        <el-col class="gauge__chart no-padding">
          <div ref="gauge" />
          <svg
            viewBox="0 0 50 15"
            class="gauge__needle"
            :style="`transform: rotate(${needleDeg}deg);`"
          >
            <path
              d="M 0 7.25 L 27.5 11 C 35 11 35 3.5 27.5 3.5 L 0 7.25"
              fill="#bbbbbb"
            />
          </svg>
        </el-col>
        <p class="gauge__title legend">
          <el-popover
            placement="right"
            width="160"
          >
            <div style="text-align: right; margin: 0">
              <el-input-number
                v-if="detectionType === 'Car' "
                v-model="carMax"
                :min="1"
                size="mini"
                controls-position="right"
              />
              <el-input-number
                v-else
                v-model="peopleMax"
                :min="1"
                size="mini"
                controls-position="right"
              />
            </div>
            <el-button
              slot="reference"
              type="text"
              size="mini"
            >
              Number of {{ detectionType }}
            </el-button>
          </el-popover>
        </p>
      </el-col>
      <el-col
        class="date"
        :span="7"
      >
        <el-col class="date__container">
          <span class="date__main">{{ currentTime.format("HH:mm") }}</span>
        </el-col>
        <el-col class="date__container">
          <span class="date__sub">{{ currentTime.format("dddd, DD MMMM") }}</span>
        </el-col>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      justify="center"
      align="middle"
      class="car-detection__monitor"
    >
      <flash-rtmp-player :url="videoUrl" />
      <el-button
        type="primary"
        class="close_btn"
        @click="$emit('close')"
      >
        {{ $t("button.back") }}
      </el-button>
    </el-row>
  </el-row>
</template>

<script>
import c3 from 'c3';
import moment from 'moment';
import FlashRtmpPlayer from '@/components/Monitor/FlashRtmpPlayer.vue';

export default {
  name: 'CarDetection',
  components: {
    FlashRtmpPlayer,
  },
  props: {
    event: {
      required: false,
      type: Object,
      default: () => null,
    },
    videoUrl: {
      required: false,
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      currentTime: moment(),
      chart: null,
      detectionType: 'Car',
      clock: null,
      carMax: this.$config.carMax,
      peopleMax: this.$config.peopleMax,
    };
  },
  computed: {
    gaugeMax() {
      return this.detectionType === 'Car' ? this.carMax : this.peopleMax;
    },
    needleDeg() {
      return Math.min((this.gaugeData / this.gaugeMax) * 180, 180);
    },
    dashboardTime() {
      return this.event && this.event.current_timestamp
        ? moment(this.event.current_timestamp * 1000)
        : null;
    },
    gaugeData() {
      return this.detectionType === 'Car' ? this.cars : this.peoples;
    },
    cars() {
      return this.event && this.event.objects
        ? this.event.objects.filter((object) => object.label === 'car').length
        : 0;
    },
    peoples() {
      return this.event && this.event.objects
        ? this.event.objects.filter((object) => object.label !== 'car').length
        : 0;
    },
    threshold() {
      return [this.gaugeMax * 0.5, this.gaugeMax * 0.8, this.gaugeMax];
    },
    state() {
      const index = this.threshold.findIndex((value) => value > this.gaugeData);
      switch (index) {
        case 0:
          return 'Vacant';
        case 1:
          return 'Crowded';
        default:
          return 'Full';
      }
    },
  },
  watch: {
    gaugeData() {
      this.chart.load({
        columns: [['data', this.gaugeData]],
      });
    },
    gaugeMax() {
      this.generateChart();
    },
    carMax() {
      this.$config.carMax = this.carMax;
    },
    peopleMax() {
      this.$config.peopleMax = this.peopleMax;
    },
  },
  created() {
    this.clock = setInterval(() => this.updateCurrentTime(), 1 * 1000);
  },
  mounted() {
    this.generateChart();
  },
  destroyed() {
    clearInterval(this.clock);
  },
  methods: {
    generateChart() {
      this.chart = c3.generate({
        bindto: this.$refs.gauge,
        data: {
          columns: [['data', this.gaugeData]],
          type: 'gauge',
        },
        gauge: {
          min: 0,
          max: this.gaugeMax,
        },
        color: {
          pattern: ['#60B044', '#F6C600', '#df4747'],
          threshold: {
            values: this.threshold,
          },
        },
        size: {
          height: 100,
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
      });
    },
    updateCurrentTime() {
      this.currentTime = moment();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  height: 60px;
  &.name {
    font-size: 1.1rem;
    margin-left: 2vw;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  &.selector {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.time {
    font-size: 0.75rem;
    margin-right: 2vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.car-detection {
  position: absolute;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background-color: $normal-white;
  overflow: hidden;

  &__dashboard {
    height: 160px;
  }

  &__monitor {
    height: calc(100% - 220px);

    .close_btn {
      position: absolute;
      bottom: 30px;
      right: 32px;
    }
  }
  &__film {
    width: 100%;
    height: 100%;
    background-color: $normal-black;
  }
}

.dashboard,
.gutter,
.date {
  background-color: $light-gray-100;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  &__container {
    text-align: center;
  }
  &__main {
    font-size: 3rem;
  }
  &__sub {
    font-size: 0.75rem;
  }
  &__sign {
    color: $normal-white;
    background-color: $dark-red-100;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 10px;

    &.Full {
      background-color: #df4747 !important;
    }
    &.Crowded {
      background-color: #f6c600 !important;
    }
    &.Vacant {
      background-color: #60b044 !important;
    }
  }
}

.gauge {
  &__number,
  &__title {
    margin: 0;
    text-align: center;

    &.legend {
      margin-top: 4rem;
    }
  }

  &__chart {
    position: relative;
  }
  &__number {
    color: $dark-red-100;
    font-size: 1.25rem;

    &.Full {
      color: #df4747 !important;
    }
    &.Crowded {
      color: #f6c600 !important;
    }
    &.Vacant {
      color: #60b044 !important;
    }
  }

  &__title {
    font-size: 0.5rem;
    transform: translateY(-10%) scale(0.9);

    button {
      color: black;
      cursor: default;
      border: none;
    }
  }
  &__needle {
    width: 50px;
    height: 15px;
    position: absolute;
    left: 37.23%;
    top: 72%;
    z-index: 1;
    transition: all 0.3s linear;
    transform-origin: 100% 50%;
  }
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $light-blue-250;
  &__container {
    text-align: center;
  }
  &__main {
    font-size: 3rem;
  }
  &__sub {
    font-size: 1.25rem;
  }
}
</style>
<style lang="scss">
#car-detection {
  .c3-chart-arcs-background {
    fill: $normal-white;
  }

  .c3-chart-arcs-gauge-max,
  .c3-chart-arcs-gauge-min {
    font-size: 0.75rem;
  }

  .c3-gauge-value {
    display: none;
  }
}
</style>
