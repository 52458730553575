<template>
  <div class="google-maps-thumbnail">
    <google-maps-thumbnail class="google-maps-thumbnail" :src="src" />
    <div class="infomation">

      <label class="infomation__label distance" v-if="distance != null">
        {{ distance | roundNumber }} {{ $t('map.unit.m') }}
      </label>

      <label class="infomation__label time" v-if="estimateTimeHour != null">
        {{ estimateTimeHour | leftPad(2, '0') }}:{{ estimateTimeMinute | leftPad(2, '0') }}:{{ estimateTimeSecond | leftPad(2, '0') }}
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { estimatePathTime, distancePath } from '@/utils/mission.js'
import GoogleMapsThumbnail from './Thumbnail.vue';

export default {
  name: 'MissionThumbnail',
  components: {
    GoogleMapsThumbnail,
  },
  computed: {
    src() {
      return this.mission.thumbnail;
    },
    estimateTime() {
      if (this.mission.duration != null) {
        return this.mission.duration;
      } else if (this.mission.tasks != null) {
        return estimatePathTime(this.mission.tasks);
      }
      return null;
    },
    estimateTimeSecond() {
      return this.estimateTime % 60;
    },
    estimateTimeMinute() {
      return Math.floor(this.estimateTime / 60) % 60;
    },
    estimateTimeHour() {
      return Math.floor(this.estimateTime / 60 / 60) % 60;
    },
    distance() {
      if (this.mission.distance != null) {
        return this.mission.distance;
      } else if (this.mission.tasks != null) {
        return distancePath(this.mission.tasks);
      }
      return null;
    },
  },
  props: {
    mission: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.google-maps-thumbnail {
  // 讓顯示行為接近 image
  display: inline-block;
  border: solid 1px #979797;
  position: relative;

  img {
    border: 0;
    width: 100%;
    height: 100%;
  }

  .infomation {
    position: absolute;
    padding: 0 6px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 22px;
    line-height: 22px;
    background-color: rgba(0, 0, 0, 0.57);
    color: white;

    label.distance {
      float: left;
    }
    label.time {
      float: right;
    }
  }
}
</style>