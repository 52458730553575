
export const FullscreenElement = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  }
};

export const ExitFullscreen = () => {
  if (document.cancelFullScreen) {
    document.cancelFullScreen();
  } else if (document.msCancelFullScreen) {
    document.msCancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  }
};

export const ListenFullscreenChange = (callback) => {
  if (document.onfullscreenchange !== undefined) {
    document.addEventListener('fullscreenchange', callback);
  } else if (document.onmsfullscreenchange !== undefined) {
    document.addEventListener('msfullscreenchange', callback);
  } else if (document.onmozfullscreenchange !== undefined) {
    document.addEventListener('mozfullscreenchange', callback);
  } else if (document.onwebkitfullscreenchange !== undefined) {
    document.addEventListener('webkitfullscreenchange', callback);
  }
};

export const RemoveListenFullscreenChange = (callback) => {
  if (document.onfullscreenchange !== undefined) {
    document.removeEventListener('fullscreenchange', callback);
  } else if (document.onmsfullscreenchange !== undefined) {
    document.removeEventListener('msfullscreenchange', callback);
  } else if (document.onmozfullscreenchange !== undefined) {
    document.removeEventListener('mozfullscreenchange', callback);
  } else if (document.onwebkitfullscreenchange !== undefined) {
    document.removeEventListener('webkitfullscreenchange', callback);
  }
};

export const CheckFullscreenElement = function() {
  if (document.fullscreenElement != null) {
    return document.fullscreenElement;
  } else if (document.currentFullScreenElement != null) {
    return document.currentFullScreenElement;
  } else if (document.msCurrentFullScreenElement != null) {
    return document.msCurrentFullScreenElement;
  } else if (document.mozCurrentFullScreenElement != null) {
    return document.mozCurrentFullScreenElement;
  } else if (document.webkitCurrentFullScreenElement != null) {
    return document.webkitCurrentFullScreenElement;
  } else {
    return null;
  }
};