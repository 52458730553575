<template>
  <flight-map
    ref="maps"
    :edit-mode="false"
    :fly-area-guided-click="e => singleClick(e)"
    :show-nest="mapOption.nest"
    :show-camera="mapOption.camera"
    :show-weather-data="true"
    :operator-top="193"
    @double-click="doubleClick"
  >
    <template v-for="(drone, index) in drones">
      <div
        v-if="activateDrone[index]"
        :key="`drone-${index}`"
        class="drone-items"
      >
        <drone
          v-if="dronePosition[index] != null && droneMq[index].attitude"
          :key="`map-drone-${index}`"
          :status="droneStatus[index]"
          :index="index"
          :position="dronePosition[index]"
          :attitude="droneMq[index].attitude"
          :mission="droneMq[index].current_mission"
          :selected="selectDrone && drone.id == selectDrone.id"
          @click="e => droneClick(e, index)"
        />
        <fly-router
          v-if="droneMission[index]"
          :key="`mission${index}`"
          :edit-mode="false"
          :task="droneMission[index].tasks"
          :index="index"
          :history="droneHistory[index]"
          :use-normal-icon="false"
        />
        <gmap-marker
          v-if="droneGuide[index] != null"
          :icon="guideIcon"
          :position="droneGuideGoogle[index]"
        />
      </div>
    </template>
    <slot />
    <gmap-marker
      v-if="popupIcon"
      :icon="popupIcon"
      :z-index="10000"
      :draggable="true"
      :position="popupPositionGoogle"
      @drag="drag"
      @dragend="dragend"
    />
    <gmap-polyline
      v-if="popupPolylineGoogle"
      :path="popupPolylineGoogle"
      :options="popupPolylineOption"
    />
    <gmap-info-window
      v-if="popupPosition"
      :position="popupPositionGoogle"
      :option="{disableAutoPan: true }"
      @closeclick="() => $emit('closePopup')"
    >
      <slot name="popup" />
    </gmap-info-window>
  </flight-map>
</template>

<script>
import Drone from '@/components/GoogleMaps/Drone.vue';
import FlightMap from '@/components/GoogleMaps/FlightMap.vue';
import FlyRouter from '@/components/GoogleMaps/FlyRoute.vue';
import { GeographicShorter, GeographicLonger } from '@/utils/map.js';
import { Icon } from '@/components/GoogleMaps/MarkerStyle.js';
import PolylineStyle from '@/components/GoogleMaps/PolylineStyle.js';

export default {
  name: 'MonitorMapByGoogleMaps',
  components: {
    FlightMap,
    FlyRouter,
    Drone,
  },
  props: {
    selectDrone: {
      required: true,
    },
    mapOption: {
      required: true,
      type: Object,
    },
    drones: {
      required: true,
      type: Array,
    },
    activateDrone: {
      required: true,
      type: Array,
    },
    droneMq: {
      required: true,
      type: Array,
    },
    droneMqHistory: {
      required: true,
      type: Array,
    },
    droneStatus: {
      required: true,
      type: Array,
    },
    droneMission: {
      required: true,
      type: Array,
    },
    droneGuide: {
      required: true,
      type: Array,
    },
    popupPosition: {},
    popupIcon: {},
    popupPolyline: {},
    popupPolylineOption: {
      default() {
        return PolylineStyle.clickAndGoPolylineOptions;
      },
    },
  },
  computed: {
    dronePosition() {
      return this.droneMq.map((drone, index) => {
        const { gps } = drone;
        if (gps.latitude != null && gps.longitude != null) {
          return GeographicShorter(gps);
        }
        return null;
      });
    },
    droneHistory() {
      return this.droneMqHistory.map((drone, index) => {
        if (drone.gps == null) {
          return [];
        }
        return drone.gps.map(GeographicShorter);
      });
    },
    droneGuideGoogle() {
      return this.droneGuide.map(GeographicShorter);
    },
    popupPositionGoogle() {
      if (this.popupPosition == null) {
        return null;
      }
      return GeographicShorter(this.popupPosition);
    },
    popupPolylineGoogle() {
      if (this.popupPolyline == null) {
        return null;
      }
      return this.popupPolyline.map(GeographicShorter);
    },
    guideIcon() {
      return Icon.clickAndGo;
    },
  },
  methods: {
    drag(googleEvent) {
      const position = GeographicLonger(googleEvent.latLng);
      this.$emit('update:popupPosition', position);
    },
    dragend(googleEvent) {
      const position = GeographicLonger(googleEvent.latLng);
      this.$emit('update:popupPosition', position);
      this.$nextTick(() => {
        this.$emit('popupDragend');
      });
    },
    doubleClick(googleEvent) {
      const event = {
        position: GeographicLonger(googleEvent.latLng),
        source: 'fence',
      };

      this.$emit('map-double-click', event);
    },
    droneClick(googleEvent, droneIndex) {
      const event = {
        source: 'drone',
        index: droneIndex,
      };

      this.$emit('map-click', event);
    },
    singleClick(googleEvent) {
      const event = {
        position: GeographicLonger(googleEvent.latLng),
        source: 'fence',
      };

      this.$emit('map-click', event);
    },
    findZonesInPosition(position) {
      const latLng = new google.maps.LatLng(
        position.latitude,
        position.longitude,
      );

      return this.$refs.maps.findZonesInPosition(latLng);
    },
  },
};
</script>
