<template>
  <div>
    <div class="playback-area">
      <div class="row" v-for="i in Math.ceil(players.length / 2) " :key="i">
        <playback v-for="(player, j) in players.slice((i-1) * 2, i * 2)" 
                  :key="(i-1) * 2 + j" 
                  :index="(i-1) * 2 + j"
                  :url="player.url"
                  :info="player.info"
                  :dragging="dragging"
                  @drop="drop">
        </playback>
      </div>
    </div>

    <div :class="`side-menu ${(cameraListOpen) ? 'activate' : '' }`">
      <div class="side-menu__content">
        <camera-list :drones="drones" :cameras="cameras" @dragstart="dragstart" @drop="drop"></camera-list>
      </div>
      <div class="side-toggle" v-on:click="collapseList">
        <img class="close" src="/asserts/icon/liveview-hidepane-btn.svg" />
        <img class="open" src="/asserts/icon/liveview-showpane-btn.svg" />
      </div>
    </div>
  </div>
</template>

<script>
  import {CopyObject} from '@/utils/common.js'
  import { mapGetters } from 'vuex';

  import TitleBar from '@/components/Monitor/TitleBar.vue'
  import Playback from '@/components/Monitor/Playback.vue'
  import CameraList from '@/components/Monitor/CameraList.vue'

  import DroneApi from '@/services/api/domain/group/drone';
  import CameraApi from '@/services/api/domain/group/camera'
  import LoginUserApi from '@/services/api/domain/loginUser';

  export default {
    name: 'MonitorLive',
    components: {
      TitleBar,
      Playback,
      CameraList,
    },
    data() {
      return {
        cameraListOpen: false,
        players: [
          { url: null, info: null },
          { url: null, info: null },
          { url: null, info: null },
          { url: null, info: null},
        ],
        dragging: false,
        darggingItem: null,
        drones: [],
        cameras: [],
      };
    },
    computed: {
      ...mapGetters({
        group: 'user/group',
      }),
    },
    methods: {
      allowDrop(event) {
        event.preventDefault();
      },
      dragstart(information) {
        this.darggingItem = information;
        this.dragging = true;

        document.addEventListener('drop', this.outrangeDrop);
        document.addEventListener("dragover", this.allowDrop, false);
      },
      outrangeDrop(event) {
        this.dragging = false;
        document.removeEventListener('drop', this.outDrop);
        document.removeEventListener("dragover", this.allowDrop, false);
      },
      drop({index}) {
        this.outrangeDrop();
        this.players[index].url = null;
        // make sure old player removed
        this.$nextTick(function() {
          this.players[index].url = this.darggingItem.url;
          delete this.darggingItem.url;
          this.players[index].info = this.darggingItem;
        });

        if (this.darggingItem.deviceType == 'camera') {
          LoginUserApi.updateLiveSetting({
            index: parseInt(index) + 1,
            camera_id: this.darggingItem.device.id,
            type: 'rgb',
          });
        } else {
          LoginUserApi.updateLiveSetting({
            index: parseInt(index) + 1,
            drone_id: this.darggingItem.device.id,
            type: this.darggingItem.type,
          });
        }
      },
      collapseList() {
        if (this.cameraListOpen === true) {
          this.cameraListOpen = false;
        } else {
          this.cameraListOpen = true;
        }
      },
    },
    mounted: async function () {

      this.$on('clearAll', () => {
        this.players.forEach(function(element) {
          element.url=null;
          element.info=null;
        });        
      });
      
      if (navigator.userAgent.indexOf('iPad') >= 0) {
        let ipadCssNode = document.createElement('style');
        ipadCssNode.innerHTML = '.app-fill.fullscreen { top: 20px }';
        document.body.appendChild(ipadCssNode);
      }

      let droneResponse = await DroneApi.getAll(this.group.id);
      this.drones = droneResponse.data.drones;

      let cameraResponse = await CameraApi.getAll(this.group.id);
      this.cameras = cameraResponse.data.cameras;

      let loadedSetting = false;
      let settingResponse = await LoginUserApi.getSetting();
      let groupSetting = null;
      if (settingResponse.data == null || settingResponse.data.group_live_views == null) {
        return;
      }
      for (let group of settingResponse.data.group_live_views) {
        if (group.gid === this.group.id) {
          groupSetting = group;
          break;
        }
      }

      if (groupSetting == null || groupSetting.live_views == null) {
        this.cameraListOpen = true;
        return;
      }

      for (let view of groupSetting.live_views) {
        let playerIndex = view.index - 1;
        let player = this.players[playerIndex];
        let device;

        if (view.drone_id) {
          device = this.drones.find((drone) => {
            return drone.id === view.drone_id;
          })
        } else if (view.camera_id) {
          device = this.cameras.find((camera) => {
            return camera.id === view.camera_id;
          });
        }

        if (device == null) {
          continue
        }

        loadedSetting = true;
        player.info = {
          type: view.type,
          device,
        };

        let url = device[`${view.type}_url`];
        player.url = url;
      }

      if (!loadedSetting) {
        this.cameraListOpen = true;
      }
    },
  }

</script>

<style lang="scss">
.app-fill.fullscreen {
  .playback-area {
    height: calc(100% - 48px);

    .row {
      position: absolute;
      height: 50%;
      width: 100%;
    }

    .row:nth-child(2) {
      top: 50%;
    }
  }

  .playback {
    position: absolute;
    height: 100%;
    width: 50%;
  }

  .playback:nth-child(2) {
    left: 50%;
  }

  .playback__wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.playback-area{
  position: absolute;
  width: 100%;

  /* Hotfix for shards Vue */
  .row {
    display: initial !important;
    flex-wrap: initial !important;
    margin-left: initial !important;
    margin-right: initial !important;
  }

  :first-child.row {
    .playback__wrapper {
      border-top-width: 1px
    }
  }
  .row > :first-child {
    .playback__wrapper {
      border-left-width: 1px
    }
  }
  .playback__wrapper {
    width: 50%;
    position: relative;
    float: left;
    border-style: solid;
    border-width: 0px 1px 1px 0px;
    border-color: #848484;
  }
  .playback__wrapper:after {
    padding-top: 56.25%;
    /* 16:9 ratio */
    display: block;
    content: '';
  }

  .playback__wrapper.fullscreen {
    width: 100%
  }
  
  .playback__content {
    position: absolute;
    top: 0;
    left: 0;
    color: black;
  }
}

$side-menu-width: 252px;

.side-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  .side-menu__content {
    display: none;
  }  
  .side-toggle {
    display: initial;
    position: absolute;
    width: 13px;
    // firefox not support 
    // height: -moz-fit-content;
    // height: fit-content;
    height: 33.3px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 0;
    .close {
      display: none;
    }

    .open {
      display: initial;
    }
  }
}

.side-menu.activate {
  width: $side-menu-width;
    .side-menu__content {
    display: initial;
  }  
  .side-toggle{
    left: $side-menu-width;
    .close {
      display: initial;
    }

    .open {
      display: none;
    }
  }
}

</style>