<template>
  <div class="title-bar">
    <el-button-group>
      <button
        class="title-bar__button image-button assign"
        @click="assign"
      />
    </el-button-group>

    <el-button-group class="center tab-switch">
      <el-button
        size="mini"
        type="default"
        :class="`${(selectItem == 'live') ? 'activate' : ''}`"
        plain
        @click="tabChange('live')"
      >
        {{ $t('page.monitor.titleBar.live') }}
      </el-button>
      <el-button
        size="mini"
        type="default"
        :class="`${(selectItem == 'map') ? 'activate' : ''}`"
        plain
        @click="tabChange('map')"
      >
        {{ $t('page.monitor.titleBar.map') }}
      </el-button>
    </el-button-group>
    <assign-mission-dialog ref="assignMission" />
    <slot />
  </div>
</template>

<script>
import AssignMissionDialog from '@/components/AssignMissionDialog.vue';

export default {
  name: 'TitleBar',
  components: {
    AssignMissionDialog,
  },
  props: {
    selectItem: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      currentRoute: this.$router.currentRoute.name,
    };
  },
  methods: {
    assign() {
      this.$refs.assignMission.open();
    },
    tabChange(tab) {
      this.$emit('tab-change', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-bar {
  margin: 10px 8px 8px 8px;
  position:relative;

  .title-bar__button {
    cursor: pointer;
    width: 48px;
    height: 30px;
    float: left;
    margin-right: 12px;
    background-color: transparent;
  }

  .title-bar__button.assign {
    background-image: url('/asserts/icon/monitor-load-mission-btn-normal.svg');
  }

  .title-bar__button.assign:hover {
    background-image: url('/asserts/icon/monitor-load-mission-btn-focus.svg');
  }

  .el-button-group.center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .el-button-group.tab-switch {
    button {
      border-color: #939393;
      color: #939393;
    }

    button.activate {
      background-color: #939393;
      color: white;
    }
  }

}
</style>
