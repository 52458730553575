<template>
  <div>
    <gmap-marker ref="markerBackground"
                 :position="position"
                 :icon="iconBase"
                 :z-index="50000 + index * 10 + 1"
                 @click="$emit('click')"
    />
    <gmap-marker ref="markerMain"
                 :position="position"
                 :icon="iconMain"
                 :z-index="50000 + index * 10 + 2"
                 @click="$emit('click')"
    />
    <gmap-marker ref="markerSub"
                 :position="position"
                 :icon="iconSub"
                 :z-index="50000 + index * 10 + 3"
                 @click="$emit('click')"
    />
  </div>
</template>

<script>
  import { DroneStyle } from '@/components/GoogleMaps/MarkerStyle.js';
  import { CopyObject } from '@/utils/common.js';

  export default {
    name: 'Drone',
    components: {
    },
    props: {
      index: {
        type: Number,
        required: true,
      },
      position: {
        type: Object,
        required: true,
      },
      attitude: {
        type: Object,
        required: true,
      },
      mission: {
        type: Object,
        required: true,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      offline: {
        type: Boolean,
        default: false,
      },
      status: {
        type: Object,
        required: true,
      },
    },
    computed: {
      iconBase() {
        let icon = CopyObject(DroneStyle.base[(this.selected) ? 'activate' : 'normal']);
        return icon;
      },
      iconMain() {
        let icon = CopyObject(DroneStyle[this.status.simple].main);
        // rotation for PATH ONLY
        icon.rotation = this.attitude.yawAngle;
        return icon;
      },
      iconSub() {
        let icon = CopyObject(DroneStyle[this.status.simple].sub);
        // rotation for PATH ONLY
        icon.rotation = this.attitude.yawAngle;
        return icon;
      },
    },
  };
</script>

<style lang="scss">
</style>