<template>
  <div class="overlay nest-controller-side">
    <div :class="{'button-container': true, activate: enable}">
      <button
        :class="`image-button nest`"
        v-on:click="enable = !enable"
      ></button>
    </div>

    <div
      class="nest-controller-content"
      v-if="enable"
    >
      <div class="nest-list">
        <div
          v-for="(nest, index) in nests"
          :key="`nest-${index}`"
          :class="{'nest': true, 'activate': selectItem == index}"
          v-on:click="selectItem = index"
          :title="nest.name"
        >
          <div class="nest-icon">
            <img
              src="/asserts/icon/map-nest-icon-open.svg"
              v-show="['opened', 'opening'].indexOf(nestMq[index].heartbeat.cover_status) >= 0"
            />
            <img
              src="/asserts/icon/map-nest-icon-close.svg"
              v-show="['closed', 'closing'].indexOf(nestMq[index].heartbeat.cover_status) >= 0"
            />
          </div>
          <label class="nest-name">{{ nest.name }}</label>
        </div>
      </div>
      <div class="hr"></div>
      <div class="nest-controller-toggle">
        <div class="nest-controller-toggle__content">
          <el-switch
            v-model="selectNestOpen"
            active-color="#ffc900"
            inactive-color="#FFFFFF"
            :disabled="!isAdmin || !selectNestOnline || selectNestOperatoring"
          >
          </el-switch>
          <span
            class="status"
            v-if="selectNestOpen"
          >{{ $t('map.nest.open') }}</span>
          <span
            class="status"
            v-else
          >{{ $t('map.nest.close') }}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import NestApi from "@/services/api/domain/group/nest";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "NestControl",
  data() {
    return {
      enable: false,
      selectItem: 0
    };
  },
  props: {
    nests: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      group: "user/group",
      isAdmin: "user/isAdmin",
      nestLatestMessage: "mqtt/nestLatestMessage"
    }),
    nestMq() {
      return this.nests.map(nest => {
        return {
          heartbeat: _.get(this.nestLatestMessage, [nest.id, "heartbeat"], {}),
          timeout: _.get(this.nestLatestMessage, [nest.id, "timeout"], {})
        };
      });
    },
    selectNestOpen: {
      async set(value) {
        let command;
        let coverStatus;
        if (value) {
          command = "open_cover";
          coverStatus = "opening";
        } else {
          command = "close_cover";
          coverStatus = "closing";
        }
        let nest = this.nests[this.selectItem];

        const loading = this.$loading({
          lock: true
        });
        try {
          let status = await NestApi.execute(this.group.id, nest.id, command);
          this.nestMq[this.selectItem].heartbeat.cover_status = coverStatus;
          this.$showSuccess(status.msg);
        } catch (error) {
          this.$showFail(error);
        } finally {
          loading.close();
        }
      },
      get() {
        return (
          ["opened", "opening"].indexOf(
            this.nestMq[this.selectItem].heartbeat.cover_status
          ) >= 0
        );
      }
    },
    selectNestOperatoring() {
      let coverStatus = this.nestMq[this.selectItem].heartbeat.cover_status;
      return (
        typeof coverStatus === "string" &&
        ["opening", "closing"].indexOf(coverStatus) >= 0
      );
    },
    selectNestOnline() {
      return (
        this.nestMq[this.selectItem].timeout &&
        this.nestMq[this.selectItem].timeout.alive
      );
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
.overlay.nest-controller-side {
  top: 20px;
  left: initial;
  right: 0px;
  width: 63px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px 0 0 4px;

  .button-container {
    border: 0;
    margin: 0;
    height: 55px;
    width: 63px;
    position: relative;
  }

  .button-container.activate {
    // background-color: rgba(0, 0, 0, 0.5);
    border-right: 6px solid #ffc900;
  }

  .button-container:first-child {
    border-top-left-radius: 4px;
  }

  .button-container:last-child {
    button {
      border-bottom-style: none;
    }
    border-bottom-left-radius: 4px;
  }

  button {
    margin-left: 3px;
    margin-right: 4px;
    color: white;
    width: 56px;
    height: 55px;
  }

  button:focus {
    outline: 0;
  }

  button.image-button.nest {
    background-image: url("/asserts/icon/location-nest-icon.svg");
  }

  .nest-controller-content {
    position: absolute;
    top: 0;
    right: 67px;

    padding: 10px;
    width: 172px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: column;

    .nest-list {
      width: 160px; // 172px - 10px * 2 + 4px * 2
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: -4px; // remove outer padding
      margin-bottom: 9px;
      .nest {
        text-align: center;
        margin: 4px;
        .nest-icon {
          width: 72px;
          height: 55px;
          background-color: #9c9c9c;
          margin-bottom: 4px;
          position: relative;
          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
        .nest-name {
          font-size: 12px;
          width: 72px;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .nest.activate {
        .nest-icon {
          border: solid 2px #ffc900;
        }
      }
    }
    .hr {
      color: #979797;
      background-color: #979797;
      height: 1px;
      border: 0;
      border-radius: 4.5px;

      // border-radius: 4.5px;
      // border-width: 2px;
    }

    .nest-controller-toggle {
      flex-grow: 1;
      margin-top: 17px;
      margin-bottom: 10px;
      .nest-controller-toggle__content {
        text-align: center;
        margin-top: 3px;
        margin-bottom: 3px;
      }
      span.status {
        font-size: 14px;
        margin-left: 8px;
      }
      .el-switch {
        .el-switch__core {
          width: 44px;
          height: 27px;
          border-radius: 18.6px;
          border: solid 1.8px rgba(0, 0, 0, 0.1);
        }

        .el-switch__core:after {
          width: 24.2px;
          height: 24.4px;
          border: solid 0.6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05),
            0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 4px 2px 0 rgba(0, 0, 0, 0.05);
          top: -1px;
          left: -1px;
        }
      }
      .el-switch.is-checked {
        .el-switch__core::after {
          margin-left: -24px;
          left: 100%;
        }
      }
    }
  }
}
</style>