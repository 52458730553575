<template>
  <div class="gimbal-control">
    <div class="shutter">
      <button
        class="image-button shutter-button"
        @click="snapshot"
      />
    </div>

    <div
      v-if="drone.model=='G5.2'"
      class="shutter1"
    >
      <button
        v-if="!record"
        class="image-button start-button"
        @click="recordPress"
      />
      <button
        v-if="record"
        class="image-button stop-button"
        @click="recordPress"
      />
    </div>
    <div
      class="scale"
      :style="scaleTopStyle"
    >
      <span>{{ lastSlider }}x</span>
    </div>

    <div
      class="setting-altitude-slider-supplement-down"
      :style="topStyle"
    />
    <el-slider
      v-model="sliderValues"
      :vertical="true"
      height="195px"
      :step="1"
      :show-stops="true"
      :style="backgroundTopStyle"

      range
      class="setting-altitude-slider-background"
      disabled
      :min="0"
      :max="11"
    />

    <el-slider
      ref="slider"
      v-model="sliderValues"
      :vertical="true"
      height="160px"
      :step="1"
      :show-stops="false"
      :style="gimbalTopStyle"
      class="setting-altitude-slider-gimbal"

      :min="1"
      :max="10"
      @change="syncSliderValue"
    />

    <div
      v-if="drone.model=='G5.2'"
      class="auto"
    >
      <button
        class="image-button auto-button"
        @click="autoFocus"
      />
      <button
        class="image-button add-button"
        @click="addFocus"
      />
      <el-slider
        ref="sliderAuto"
        v-model="sliderValues"
        :vertical="true"
        height="100px"
        :step="1"
        :show-stops="false"
        class="setting-altitude-slider-auto"
        :min="1"
        :max="10"
        @change="syncSliderValue"
      />
      <button
        class="image-button sub-button"
        @click="subFocus"
      />
    </div>

    <div class="circle-control tilt-control">
      <label class="center">{{ $t('page.monitor.control.tilt') }}</label>
      <span>
        <input
          :value="tiltStage"
          :class="{'center': true,
                   'tilt-control__input': true,
                   'tilt-control__input--error': tiltErr}"
          @input="inputTilt"
          @keypress="preventExceed($event, -90, 15)"
        >
      </span>
      <circle-slider
        v-model="tilt"
        class="silder"
        :side="120"
        :circle-width="5"
        :progress-width="5"
        :min="-103"
        :max="257"
        :limit-min="-90"
        :limit-max="15"
        :keep-max-min="false"
        circle-color="#d8d8d8"
        disabled-color="#757975"
        progress-color="#ffc900"
        knob-color="#ffffff"
        @input-final="setTilt"
      />
    </div>
    <div class="circle-control heading-control">
      <label class="center">{{ $t('page.monitor.control.heading') }}</label>
      <span>
        <input
          :value="headingStage"
          :class="{'center': true,
                   'heading-control__input': true,
                   'heading-control__input--error': headingErr}"
          @input="inputHeading"
          @keypress="preventExceed($event, 0, 360)"
        >
      </span>
      <circle-slider
        v-model="heading"
        class="silder"
        :side="120"
        :circle-width="5"
        :progress-width="5"
        :min="0"
        :min-angle="Math.PI / 2 * 3"
        :max="361"
        :keep-max-min="false"
        circle-color="#d8d8d8"
        progress-color="#ffc900"
        knob-color="#ffffff"
        @input-final="setHeading"
      />
    </div>
  </div>
</template>

<script>
import DroneApi from '@/services/api/domain/group/drone';
import { mapActions, mapGetters } from 'vuex';
import { logger } from '@/logger/index';

export default {
  name: 'GimbalControl',
  props: {
    drone: {
      required: true,
      type: Object,
    },
    droneMq: {
      required: true,
      type: Object,
    },
    gimbal: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      tilt: 15,
      tiltStage: 15,
      tiltApi: null,
      tiltErr: false,
      heading: 0,
      headingStage: 0,
      headingApi: null,
      headingErr: false,
      lastSlider: 1,
      sliderValues: [0, 0],
      record: false,
    };
  },
  methods: {
    async snapshot() {
      logger.debug('snapshot');
      let shot_cmd = 'take_pic';
      if (this.gimbal == 2) shot_cmd = 'take_pic2';
      const status = await DroneApi.execute(this.group.id, this.drone.id, shot_cmd, {
      });
      this.$showInfo('snapshot successfully');
    },

    async recordPress() {
      logger.debug('recordPress');
      this.record = !this.record;
      let value = 0;
      if (this.record) {
        value = 1;
      } else {
        value = 0;
      }
      const status = await DroneApi.execute(this.group.id, this.drone.id, 'camera_recording', { values: [value] });
      if (this.record) this.$showInfo('start recording successfully');
      else this.$showInfo('end recording successfully');
    },

    async addFocus() {
      logger.debug('addFocus');
      const value = 1;
      const status = await DroneApi.execute(this.group.id, this.drone.id, 'camera_focus', { values: [value] });
      this.$showInfo('+focus successfully');
    },

    async subFocus() {
      logger.debug('subFocus');
      const value = -1;
      const status = await DroneApi.execute(this.group.id, this.drone.id, 'camera_focus', { values: [value] });
      this.$showInfo('-focus successfully');
    },

    async autoFocus() {
      logger.debug('autoFocus');
      const value = 0;
      const status = await DroneApi.execute(this.group.id, this.drone.id, 'camera_focus', { values: [value] });
      this.$showInfo('auto focus successfully');
    },

    async syncSliderValue() {
      logger.debug('syncSliderValue');

      // logger.debug(this.$refs.slider.value[0]);
      // logger.debug(this.$refs.slider.value[1]);
      //  if(this.sliderValues[0]!==this.lastSlider)
      //     this.sliderValues[1]=this.sliderValues[0];
      //   else
      //     this.sliderValues[0]=this.sliderValues[1];
      //   if(this.sliderValues[0]<1 ){
      //     this.sliderValues[0]=1;
      //     this.sliderValues[1]=1;
      //   }
      //   if(this.sliderValues[0]>10 ){
      //     this.sliderValues[0]=10;
      //     this.sliderValues[1]=10;
      //   }

      //   this.$refs.slider.setValues(this.sliderValues);
      this.lastSlider = this.$refs.slider.value;
      let zoom_cmd = 'camera_zoom';
      if (this.gimbal == 2) zoom_cmd = 'camera_zoom2';
      // this.$mqtt.zoom(this.group,this.drone,this.lastSlider);
      const status = await DroneApi.execute(this.group.id, this.drone.id, zoom_cmd, {
        zoom: this.lastSlider,
      });
      this.$showInfo('zoomed in/out successfully');
    },

    async setTilt() {
      logger.debug(this.drone.model);
      logger.debug(this.gimbal);
      const camera = { camera: this.gimbal };
      let tilt_cmd = 'gimbal_tilt';
      if (this.gimbal == 2) tilt_cmd = 'gimbal_tilt2';

      const status = await DroneApi.execute(this.group.id, this.drone.id, tilt_cmd, {
        tilt: this.tilt,
        ...camera,
      });

      if (this.gimbal == 1) this.gimbal1_tilt({ droneId: this.drone.id, value: this.tilt });
      else this.gimbal2_tilt({ droneId: this.drone.id, value: this.tilt });
      if (this.tilt === 0) this.tiltStage = 0;
      logger.debug('setTilt=>done');
    },

    async setHeading() {
      const status = await DroneApi.execute(this.group.id, this.drone.id, 'change_heading', {
        heading: this.heading,
      });
      if (this.heading === 0) this.headingStage = 0;
    },
    inputTilt($event) {
      this.tiltStage = $event.target.value;
      if (this.tiltStage > 15) this.tiltStage = 15;
      if (this.tiltStage < -90) this.tiltStage = -90;
      const tiltNum = Number(this.tiltStage);
      if (!Number.isNaN(tiltNum)) {
        this.tiltErr = false;
        this.tilt = tiltNum;
        clearTimeout(this.tiltApi);
        this.tiltApi = setTimeout(this.setTilt, 1500);
      } else {
        this.tiltErr = true;
      }
    },
    inputHeading($event) {
      this.headingStage = $event.target.value;
      if (this.headingStage < 0) this.headingStage = 0;
      if (this.headingStage > 360) this.headingStage = 360;
      const headingNum = Number(this.headingStage);
      if (!Number.isNaN(headingNum)) {
        this.headingErr = false;
        this.heading = headingNum;
        clearTimeout(this.headingApi);
        this.headingApi = setTimeout(this.setHeading, 1500);
      } else {
        this.headingErr = true;
      }
    },
    preventExceed($event, min, max) {
      if ($event.target.value < min || $event.target.value > max) $event.preventDefault();
    },
    ...mapActions({
      gimbal1_tilt: 'mqtt/gimbal1_tilt',
      gimbal2_tilt: 'mqtt/gimbal2_tilt',
    }),
  },
  mounted() {
    this.heading = this.droneMq.hud.heading;
    this.tilt = this.droneMq.hud.gimbal_tilt;
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      droneLatestMessage: 'mqtt/droneLatestMessage',
    }),
    topStyle() {
      let top = 477;
      if (this.drone.model == 'G5.2') top = 353;
      return {
        top: `${top}px`,
      };
    },
    backgroundTopStyle() {
      let top = 304;
      if (this.drone.model == 'G5.2') top = 180;
      return {
        top: `${top}px`,
      };
    },
    gimbalTopStyle() {
      let top = 318;
      if (this.drone.model == 'G5.2') top = 194;
      return {
        top: `${top}px`,
      };
    },
    scaleTopStyle() {
      let top = 284;
      if (this.drone.model == 'G5.2') top = 168;
      return {
        top: `${top}px`,
      };
    },
    tilttt() {
      if (this.gimbal === 1) {
        const value = this.droneLatestMessage[this.drone.id].gimbal1_tilt ? this.droneLatestMessage[this.drone.id].gimbal1_tilt.tilt : 0;
        return value;
      }
      const value = this.droneLatestMessage[this.drone.id].gimbal2_tilt ? this.droneLatestMessage[this.drone.id].gimbal2_tilt.tilt : 0;
      return value;

      // gimbal1_tilt: this.droneLatestMessage[drone.id].gimbal1_tilt ?
      //       this.droneLatestMessage[drone.id].gimbal1_tilt : {},
      //     gimbal2_tilt: this.droneLatestMessage[drone.id].gimbal2_tilt ?
      //       this.droneLatestMessage[drone.id].gimbal2_tilt : {},
    },
  },
  watch: {
    tilt(newVal) {
      this.tiltStage = newVal;
    },
    heading(newVal) {
      this.headingStage = newVal;
    },
    tilttt(newVal){
      this.tilt = newVal;
    },
  },
};
</script>

<style lang="scss">
  .gimbal-control {
    position: fixed;
    top: 212px;
    right: 69px;

    .setting-altitude-slider-supplement-down{

          left: initial;
          right: 38px;
          width: 6px;
          height: 8px;
          border-radius: 0 0 3px 3px;
          background-color: #ffc900;
          position: fixed;
    }

    .setting-altitude-slider-background{
      position: fixed;
      // top: 304px;
      left: initial;
      right: 22px;

      .el-slider__runway {
        //background-color: rgba(0,0,0, 0.65);
      }

     .el-slider__bar {
        background-color: #ffc900;
      }

      .el-slider__runway {
          width: 0px;
          height: 100%;
          margin: 0 16px;

          .el-slider__button {
            width: 0px;
            height: 0px;
            border: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }
      }

      .el-slider__stop {
          position: absolute;
          height: 2px;
          width: 6px;
          /* border-radius: 100%; */
          margin-left: -14px;
          background-color: rgba(0,0,0,0.65);
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
      }

    }

    .setting-altitude-slider-gimbal{
      position: fixed;
      top: 318px;
      left: initial;
      right: 22px;

      .el-slider__runway {
        background-color: rgba(0,0,0, 0.65);
      }

     .el-slider__bar {
        background-color: #ffc900;
        border-radius: 0 0 0px 0px;
      }

      .el-slider__runway {
          width: 6px;
          // height: 100%;
          margin: 0 16px;
          height:166px;
          .el-slider__button {
            width: 16px;
            height: 16px;
            border: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }
      }

      .el-slider__stop {
          // position: absolute;
          // height: 2px;
          // width: 6px;
          // /* border-radius: 100%; */
          // margin-left: -11px;
          // background-color: rgba(0,0,0,0.65);
          // -webkit-transform: translateX(-50%);
          // transform: translateX(-50%);
      }

    }

    .setting-altitude-slider-auto{
      position: relative;
      .el-slider__runway {
        background-color: rgba(0,0,0, 0.65);
      }

     .el-slider__bar {
        background-color: #ffc900;
        border-radius: 0 0 0px 0px;
      }

      .el-slider__runway {
          width: 6px;
          // height: 100%;
          margin: 0 16px;
          height:100px;
          .el-slider__button {
            width: 16px;
            height: 16px;
            border: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          }
      }

      .el-slider__stop {
          // position: absolute;
          // height: 2px;
          // width: 6px;
          // /* border-radius: 100%; */
          // margin-left: -11px;
          // background-color: rgba(0,0,0,0.65);
          // -webkit-transform: translateX(-50%);
          // transform: translateX(-50%);
      }

    }

    .shutter {
      position: fixed;
      left: 79px;
      top: 392px;
      .image-button.shutter-button {
        // cursor: not-allowed;
        width: 60px;
        height: 60px;
        background-image: url('/asserts/icon/map-gimbalcontrol-shutter.svg');
        outline:none;
      }
    }

    .shutter1 {
      position: fixed;
      left: 79px;
      top: 500px;
      .image-button.start-button {
        // cursor: not-allowed;
        width: 60px;
        height: 60px;
        background-image: url('/asserts/icon/map-gimbalcontrol-recording-start.svg');
        outline:none;
      }
      .image-button.stop-button {
        // cursor: not-allowed;
        width: 60px;
        height: 60px;
        background-image: url('/asserts/icon/map-gimbalcontrol-recording-stop.svg');
        outline:none;
      }
    }

    .auto{
      position: fixed;
      left: initial;
      right: 23px;
      top: 384px;
      display: grid;
      justify-items: center;
      .image-button.auto-button {
        // cursor: not-allowed;
        width: 36px;
        height: 36px;
        background-image: url('/asserts/icon/map-gimbalcontrol-focus-auto-off.svg');
        outline:none;
      }

      .image-button.add-button {
        // cursor: not-allowed;
        width: 14px;
        height: 14px;
        background-image: url('/asserts/icon/map-gimbalcontrol-focus-add.svg');
        outline:none;
        margin-top: 6px;
      }

      .image-button.sub-button {
        // cursor: not-allowed;
        width: 14px;
        height: 14px;
        background-image: url('/asserts/icon/map-gimbalcontrol-focus-sub.svg');
        outline:none;
        margin-top: 6px;
      }
    }

    .scale{
      // top: 284px;168px
      left: initial;
      right: 29px;
      width: 28px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 4px 4px 4px 4px;
      position: fixed;

      span{
        margin-left: 6px;
      }
    }

    .circle-control.heading-control {
      margin-top: 50px;
    }

    .circle-control {
      position: relative;
      width: 134px;
      height: 134px;
      display: -webkit-flex; /* Safari */
      display: flex;
      -webkit-align-items: center; /* Safari 7.0+ */
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      text-align: center;

      color: white;
      background-color: rgba(0,0,0, 0.65);
      -webkit-clip-path: circle();
      clip-path: circle();

      label.center {
        position: absolute;
        top: 0;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
        height: 12px;
        width: fit-content;
      }

      input.center,
      span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 37px;
        height: 20px;
        border: 0;
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
      }

      .silder {
        width: 120px;
        height: 120px;
      }
    }
  }

  .tilt-control,
  .heading-control {
    span::after {
      content: "°";
      position: absolute;
      top: 0;
      right: 0;
    }

    &__input {
      color: white;

      &--error {
        color: red;
      }
    }
  }
</style>
