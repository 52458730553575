<template>
  <div>
    <div class="drone-statistics">
      <label class="title">
        <img src="/asserts/icon/mapview-dronestatus-icon.svg">
      </label>
      <span>{{ $t("map.title.drone.total") }} {{ droneStatistics.total }}</span>
      <label class="label-group">
        <img src="/asserts/icon/dronestatus-ready-icon.svg">
      </label>
      <span class="span-group">{{ droneStatistics.standby }}</span>
      <label class="label-group">
        <img src="/asserts/icon/dronestatus-offline-icon.svg">
      </label>
      <span class="span-group">{{ droneStatistics.offline }}</span>
      <label class="label-group">
        <img src="/asserts/icon/dronestatus-charging-icon.svg">
      </label>
      <span class="span-group">{{ droneStatistics.charging }}</span>
      <label class="label-group">
        <img src="/asserts/icon/dronestatus-error-icon.svg">
      </label>
      <span class="span-group">{{ droneStatistics.error }}</span>
    </div>

    <!-- weather statistics -->
    <el-tooltip
      class="item"
      effect="light"
      :content="firstWeatherStation ? firstWeatherStation.name : ''"
    >
      <div class="weather-statistics">
        <label class="weather-station">
          <img src="/asserts/icon/small-weather-station-icon.svg">
          <span class="value">{{ getTransTmp(firstWeatherHeartbeat.temperature_outside) | roundNumber(0, "--") }}</span><span>{{ tmpUnitHint }}</span>
        </label>

        <label class="wind-direction">
          <img src="/asserts/icon/map-wind-direction-icon.svg">
          <span class="value">{{ getTransSpeed(firstWeatherHeartbeat.wind_speed) | roundNumber(0, "--") }}</span><span>{{ speedUnitHint }}</span>
        </label>
      </div>
    </el-tooltip>
    <!-- weather statistics end -->

    <!--weather statistics under 970px-->
    <div class="weather-statistics-tablet">
      <label
        v-if="!switchWeatherStatistics"
        class="weather-station"
        @click="switchStatistics"
      >
        <img src="/asserts/icon/small-weather-station-icon.svg">
        <span class="value">{{ getTransTmp(firstWeatherHeartbeat.temperature_outside) | roundNumber(0, "--") }}</span><span>{{ tmpUnitHint }}</span>
      </label>

      <label
        v-else
        class="wind-direction"
        @click="switchStatistics"
      >
        <img src="/asserts/icon/map-wind-direction-icon.svg">
        <span class="value">{{ getTransSpeed(firstWeatherHeartbeat.wind_speed) | roundNumber(0, "--") }}</span><span>{{ speedUnitHint }}</span>
      </label>
    </div>
    <!--  weather-statistics under 970px end  -->

    <div class="map-toggle">
      <drone-dropdown
        class="drone-dropdown"
        :drones="drones"
        :value="dropDownData"
        @input="toggleMapDrones"
      />
      <button
        :class="{ 'image-button': true, activate: mapOption.nest }"
        @click="toggleMapItem('nest')"
      >
        {{ $t("map.nest.name") }}
      </button>
      <button
        :class="{ 'image-button': true, activate: mapOption.camera }"
        @click="toggleMapItem('camera')"
      >
        {{ $t("map.ipCam.name") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DroneDropdown from '@/views/admin/monitor/map/DroneDropdown.vue';
import { TmpMeasure, SpeedMeasure } from '@/utils/measure';
import weatherApi from '@/services/api/domain/group/weather';

export default {
  name: 'MapTitle',
  components: {
    DroneDropdown,
  },
  props: {
    mapOption: {
      required: true,
      type: Object,
    },
    drones: {
      required: true,
      type: Array,
    },
    droneStatistics: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      source: {
        weatherItems: [],
      },
      switchWeatherStatistics: false,
      timer: null,
      loadingWeatherData: false,
    };
  },
  computed: {
    firstWeatherStation() {
      return this.source.weatherItems && this.source.weatherItems[0] ? this.source.weatherItems[0] : null;
    },
    firstWeatherHeartbeat() {
      if (this.firstWeatherStation) {
        const mqMessage = this.weatherLatesetMessage[this.firstWeatherStation.id];
        if (mqMessage != null && mqMessage.heartbeat != null) {
          return mqMessage.heartbeat;
        }
      }
      return {};
    },
    dropDownData() {
      const { displayDrones } = this.mapOption;
      return displayDrones && displayDrones[this.group.id] ? displayDrones[this.group.id] : [];
    },
    ...mapGetters({
      authUser: 'user/user',
      groups: 'user/groups',
      group: 'user/group',
      weatherLatesetMessage: 'mqtt/weatherLatesetMessage',
    }),
    speedUnitHint() {
      return SpeedMeasure.unit();
    },
    tmpUnitHint() {
      return TmpMeasure.unit();
    },
  },
  async created() {
    // await this.simulateWeather();
    await this.loadWeatherData();
    if (this.source.weatherItems && this.source.weatherItems.length > 0) {
      this.source.weatherItems.forEach((station) => {
        this.subscribeWeather({ weather: station });
      });
      this.updateWeatherStatus();
      this.timer = setInterval(this.updateWeatherStatus, 60 * 1000);
    }
  },
  async destroyed() {
    if (this.source.weatherItems && this.source.weatherItems.length > 0) {
      this.source.weatherItems.forEach((station) => {
        this.unsubscribeWeather({ weather: station });
      });
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  methods: {
    ...mapActions({
      subscribeWeather: 'mqtt/subscribeWeather',
      unsubscribeWeather: 'mqtt/unsubscribeWeather',
      setWeatherStatus: 'mqtt/setWeatherStatus',
    }),
    toggleMapItem(item) {
      this.$emit('update:mapOption', {
        ...this.mapOption,
        [item]: !this.mapOption[item],
      });
    },
    toggleMapDrones(data) {
      if (data.length === 0) {
        return;
      }
      this.$emit('update:mapOption', {
        ...this.mapOption,
        displayDrones: {
          ...this.mapOption.displayDrones,
          [this.group.id]: data,
        },
      });
    },
    async loadWeatherData() {
      try {
        const { data } = await weatherApi.getAll(this.group.id);
        this.source.weatherItems = data.stations;
      } catch (error) {
        this.$showFail(error);
      }
    },
    async updateWeatherStatus() {
      if (this.loadingWeatherData || this.source.weatherItems == null || this.source.weatherItems.length === 0) {
        return;
      }
      this.loadingWeatherData = true;
      const stations = this.source.weatherItems;
      for (let i = 0; i < stations.length; i += 1) {
        const station = stations[i];
        try {
          const data = await weatherApi.status(this.group.id, station.station_id);
          this.setWeatherStatus({
            weather: station,
            payload: data,
          });
        } catch (error) {}
      }
      this.loadingWeatherData = false;
    },
    switchStatistics() {
      this.switchWeatherStatistics = !this.switchWeatherStatistics;
    },
    getTransSpeed(value, digit = 0) {
      return SpeedMeasure.display(value, digit, 0);
    },
    getTransTmp(value, digit = 0) {
      return TmpMeasure.display(value, digit, 0);
    },
  },
};
</script>

<style lang="scss">
.drone-statistics {
  position: absolute;
  left: 90px;
  top: 0;
  bottom: 0;
  margin-top: 3px;
  margin-bottom: auto;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  label {
    margin-left: 8px;
    margin-right: 2px;
  }
  label.title {
    margin-left: 0;
    margin-right: 8px;
  }
  @include tablet-medium-919px {
    left: 8%;
  }
  @include tablet-medium-832px {
    left: 14%;
  }
  .label-group,
  .span-group {
    @include tablet-medium-832px {
      display: none;
    }
  }
}

.weather-statistics,
.weather-statistics-tablet {
  font-size: 12px;
  label {
    img {
      width: 18px;
      height: 21px;
    }

    span.value {
      font-family: HelveticaNeue;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #242424;
      display: inline-block;
      width: 20px;
      margin-right: 3px;
    }
  }
}

.weather-statistics {
  @include tablet-large-970px {
    display: none;
  }
  position: absolute;
  right: 200px;
  top: 0;
  bottom: 0;
  margin-top: -1.5px;
  margin-bottom: auto;
  height: fit-content;

  label {
    img {
      width: 18px;
      height: 21px;
    }

    span.value {
      font-family: HelveticaNeue;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #242424;
      display: inline-block;
      width: 20px;
      margin-right: 3px;
    }
  }

  label.wind-direction {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.weather-statistics-tablet {
  display: none;
  @include tablet-large-970px {
    display: block;
    position: absolute;
    right: 30%;
    top: 2px;
    bottom: 0;
    margin-top: -1.5px;
    margin-bottom: auto;
    height: fit-content;
    .weather-station,
    .wind-direction {
      cursor: pointer;
      img {
        vertical-align: bottom;
      }
    }
  }
}

.map-toggle {
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;

  > button {
    background-image: url("/asserts/icon/mapview-invisible-icon.svg");
    background-repeat: no-repeat;

    background-position: 0 50%;
    padding-left: 20px;
    padding-right: 5px;
  }

  > button.disable {
    cursor: not-allowed;
  }

  > button.activate {
    background-image: url("/asserts/icon/mapview-visible-icon.svg");
  }
}
</style>
