<template>
  <default-dialog :show-dialog="show"
                  width="716px"
                  :title="$t('page.mission.export')"
                  :next-button-text="$t('button.export')"
                  :center="true"
                  footer-align="center"
                  @close="close"
                  @next="exportMission">
    <template slot="main">
      <div class="mission-export">
        <label class="export-mission-label" v-if="!preSelectMission">{{$t('page.mission.filename')}}</label>
        <el-input v-model="fileName" class="mission-export-name" />
      </div>
      <div class="mission-selection">        
        <div :class="{'missions': true, 'preSelect':  (preSelectMission != null)}">
          <div :class="{
                'mission': true,
                'activate': (select.mission == mission.id),
               }" 
               v-for="(mission, index) in missions"
               :key="`drone-${index}`"
               v-on:click="selectMission(mission)"
               :title="mission.name"
          >
            <google-maps-mission-thumbnail class="thumbnail" :mission="mission" />
            <span class="name overflow-cut" :title="mission.name">{{ mission.name }}</span>
            <span class="period">{{ FormatMissionPeriod(mission.period) }}</span>
            <img class="select" src="/asserts/icon/loadmission-selected-icon.svg">
          </div>
        </div>
      </div>
      <hr/>
      
    </template>
  </default-dialog>
</template>

<script>
import { FormatMissionPeriod } from '@/utils/mission.js';
import { mapGetters } from 'vuex';
import {logger} from "@/logger/index";
import DefaultDialog from '@/components/DefaultDialog.vue'
import DroneApi from '@/services/api/domain/group/drone';
import MissionApi from '@/services/api/domain/group/mission';
import GoogleMapsMissionThumbnail from '@/components/GoogleMaps/MissionThumbnail.vue';
import fileDownload from 'js-file-download';
export default {
  name: "AssignMissionDialog",
  components: {
    DefaultDialog,
    GoogleMapsMissionThumbnail,
  },
  data() {
    return {
      googleMapKey: this.$config.googleMapKey,
      periodUnit: this.$store.state.mission.periodUnit,
      show: false,
      drones: [],
      missions: [],
      select: {
        drone: null,
        mission: null,
      },
      fileName:null,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
    }),
  },
  mounted() {
    let _this = this;
    DroneApi.getAll(this.group.id).then((drone) => {
      _this.drones = drone.data.drones;  
    });

    if (!this.preSelectMission) {
      MissionApi.getAll(this.group.id).then((mission) => {
        this.missions = mission.data.missions;  
      });
    }
    
  },
  props: {
    preSelectMission: {
      required: false,
      type: Object,
      default: null,
    },
    customAction: {
      required: false,
      type: Boolean,
      default: false,
    },
    requireDrone: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
   
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.select.drone = null;
      this.select.mission = null;
    },
    selectMission(mission) {
      this.select.mission = mission.id;
      this.fileName=mission.name;
    },
    async exportMission() {
      logger.debug('exportMission');
      let {data} = await MissionApi.get(this.group.id, this.select.mission);
      if(this.fileName){
        await fileDownload(JSON.stringify(data), this.fileName+'.circ');
      }else{
        await fileDownload(JSON.stringify(data), data.name+'.circ');
      }
      
      this.close();     
      this.$showSuccess();      
    },
    FormatMissionPeriod,
  },
  watch: {
    preSelectMission(now) {
      // console.log("pre selection change", now, old);
      this.missions = [now];
      this.select.mission = this.missions[0].id;
    },
  },
};
</script>
<style lang="scss">
.mission-export{
  height: 42px;
  width: 716px;
  margin-left: -38px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;

  .export-mission-label {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 15px;
  }

  .mission-export-name{
    width:184px;
    height: 26px;
    .el-input__inner{
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 26px;
      line-height: 26px;
      outline: 0;
      padding: 0 15px;
      -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
      transition: border-color .2s cubic-bezier(.645,.045,.355,1);
      width: 100%;
    }
  }
}

.el-dialog {
  hr {
    color: #c5c5c5;
    background-color: #c5c5c5;
    height: 1px;
    border: 0;
    margin-left: -38px;
    margin-right: -38px;
  }
}
.mission-selection {
  max-height: 358.5px;
  overflow-x: hidden;
  overflow-y: auto;
  // overflow: auto;
  .missions {
    margin-top: 21px;
    margin-left: 8px;
    // margin-right: 40px;
    .mission {
      cursor: pointer;
      float: left;
      width: 176px;
      height: 172px;
      padding-top:12px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px transparent;
      
      position: relative;
      margin-left: 8px;
      span {
        display: block;
        margin-top: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .thumbnail {
        width: 156px;
        height: 104px;
      }
      img.select {
        display: none;
      }
    }

    .mission:first-child:last-child {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .missions:not(.preSelect) {
    .mission:hover {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
    }

    .mission.activate {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
      img.select {
        display: block;
        position: absolute;
        top: -12px;
        right: -12px;
        
      }
    }
  }
}
.drone-selection {
  line-height: 100px;
  height: 100px;
}
</style>
