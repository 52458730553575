<template>
  <div class="attitude-indicator">
    <img
      class="attitude-indicator__inner yaw-rule"
      src="/asserts/icon/map-instrument-board-drone-facing.svg?version=2"
      :style="yawRuleStyle"
    >
    <div
      class="attitude-indicator__inner pitch-rule"
      :style="pitchRuleStyle"
    />
    <img
      src="/asserts/icon/map-instrument-board-pointer-icon.svg"
      class="yaw-pointer"
    >
    <img
      src="/asserts/icon/map-instrument-board-drone-direction-icon.svg"
      class="drone"
    >
    <!-- <img src="/asserts/icon-debug/attitude-drone.png" class="drone" /> -->
  </div>
</template>

<script>
import { logger } from '@/logger/index';

export default {
  name: 'AttitudeIndicator',
  props: {
    roll: {
      required: false,
      type: Number,
      default: 0,
    },
    pitch: {
      required: false,
      type: Number,
      default: 0,
    },
    yaw: {
      required: false,
      type: Number,
      default: 0,
    },
    // sequence:{
    //   required:true,
    //   type: Number,
    //   default:-1,
    // },
  },
  data() {
    return {
      rule: {
        center: {
          x: 67,
          y: 184,
        },
        unit: 1.765,
      },
      indicatorSize: {
        x: 112,
        y: 112,
      },
      // roll: 0,
      // pitch: 0,
      // yaw: 0,
      current: 0,
      accumulated: 0,
    };
  },
  computed: {
    pitchRuleStyle() {
      const x = -this.rule.center.x + (this.indicatorSize.x / 2);
      let y = -this.rule.center.y + (this.indicatorSize.y / 2);
      if (this.pitch != null) {
        y = -this.rule.center.y + (this.indicatorSize.y / 2) + this.rule.unit * this.pitch;
      }
      return {
        'background-position': `${x}px ${y}px`,
        transform: (this.roll) ? `rotate(${this.roll}deg)` : '',
      };
    },
    yawRuleStyle() {
      logger.debug(`this.yaw:${this.yaw}`);
      if (this.yaw == null) {
        return {
          transform: '',
        };
      }
      const roundYaw = this.yaw;
      logger.debug(`after round yaw:${roundYaw}`);
      let fixedYaw = 360;
      if (roundYaw < 0) {
        fixedYaw += roundYaw;
      } else {
        fixedYaw = roundYaw;
      }
      let clockwise;
      const difference = Math.abs(fixedYaw - this.current);
      if (difference > 180) {
        if (this.yaw > 0) clockwise = (360 - difference);
        else clockwise = -(360 - difference);
      } else {
        // if(fixedYaw>this.current){
        //   clockwise=fixedYaw-this.current;
        // }else
        //   clockwise=this.current-fixedYaw;
        clockwise = fixedYaw - this.current;
      }
      this.accumulated = this.accumulated + clockwise;
      this.current = fixedYaw;
      logger.debug(`this.accumulated:${this.accumulated}`);
      logger.debug(`this.current:${this.current}`);

      return {
        transform: `rotate(${-this.accumulated}deg)`,
      };
    },

  },
  methods: {
  },
};
</script>

<style lang="scss">
.attitude-indicator {
  position: absolute;
  bottom: 7px;
  left: -147px;
  width: 133px;
  height: 133px;
  background-color: black;
  -webkit-clip-path: circle();
  clip-path: circle();
  overflow: hidden;

  .attitude-indicator__inner {
    transition: 1s;
    -webkit-transition: 1s;
  }

  .attitude-indicator__inner.pitch-rule {
    overflow: hidden;
    top: 10px;
    left: 10px;
    position: absolute;
    width: 113px;
    height: 113px;
    background-image: url("/asserts/icon/instrument-board-scale.svg");
    background-repeat: no-repeat;
    -webkit-clip-path: circle();
    clip-path: circle();
  }

  .attitude-indicator__inner.yaw-rule {
    width: 100%;
  }

  .drone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .yaw-pointer {
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    // width: 8px;
    // height: 10px;
    // background-color: #d0021b;
    // -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    // clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}
</style>
