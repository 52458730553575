<template>
  <div class="replace-mission-dialog">
    <default-dialog
      width="558px"
      :show-dialog="isDisplay"
      :body-center="true"
      :next-button-text="`\u00A0\u00A0${$t('button.yes')}\u00A0\u00A0`"
      :back-button-text="`\u00A0\u00A0${$t('button.no')}\u00A0\u00A0`"
      :center="true"
      :show-close="false"
      footer-align="center"
      @close="$emit('cancel', true)"
      @next="$emit('resetMission')"
    >
      >
      <template slot="main">
        <p class="replace-mission-dialog__header">
          {{ this.title }}
        </p>
      </template>
    </default-dialog>
  </div>
</template>

<script>
import DefaultDialog from '@/components/DefaultDialog.vue';

export default {
  name: 'MapReplaceMissionDialog',
  components: {
    DefaultDialog,
  },
  props: {
    isDisplay: {
      require: true,
      type: Boolean,
    },
    title: {
      require: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.replace-mission-dialog__header{
  text-align: center;
  padding: 3rem 0;
  font-size: 16px;
  white-space: pre-wrap;
}
</style>
