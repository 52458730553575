<template>
  <default-dialog :show-dialog="show"
                  width="716px"
                  :title="$t('page.monitor.load_mission')"
                  :next-button-text="$t('page.monitor.Next')"
                  :center="true"
                  footer-align="center"
                  @close="close"
                  @next="assignMission">
    <template slot="main">
      <div class="mission-selection">
        <label class="assign-mission-label" v-if="!preSelectMission">{{$t('page.monitor.select_mission')}}</label>
        <div :class="{'missions': true, 'preSelect':  (preSelectMission != null)}">
          <div :class="{
                'mission': true,
                'activate': (select.mission == mission.id),
               }" 
               v-for="(mission, index) in missions"
               :key="`drone-${index}`"
               v-on:click="selectMission(mission.id)"
               :title="mission.name"
          >
            <data-table-thumbnail class="thumbnail" :value="mission" />
            <span class="name overflow-cut" :title="mission.name">{{ mission.name }}</span>
            <span class="period">{{ FormatMissionPeriod(mission.period) }}</span>
            <img class="select" src="/asserts/icon/loadmission-selected-icon.svg">
          </div>
        </div>
      </div>
      <hr v-if="requireDrone" />
      <div class="drone-selection" v-if="requireDrone">
        <label class="assign-mission-label">{{$t('page.monitor.select_drone')}}</label>
        <el-select v-model="select.drone">
          <el-option
            v-for="(drone, index) in drones"
            :key="`drone-${index}`"
            :label="drone.name"
            :value="drone.id">
          </el-option>
        </el-select>
      </div>
    </template>
  </default-dialog>
</template>

<script>
import { FormatMissionPeriod } from '@/utils/mission.js';
import { mapGetters } from 'vuex';

import DefaultDialog from '@/components/DefaultDialog.vue'
import DroneApi from '@/services/api/domain/group/drone';
import MissionApi from '@/services/api/domain/group/mission';
import DataTableThumbnail from '@/components/DataTable/Thumbnail.vue';

export default {
  name: "AssignMissionDialog",
  components: {
    DefaultDialog,
    DataTableThumbnail,
  },
  data() {
    return {
      googleMapKey: this.$config.googleMapKey,
      periodUnit: this.$store.state.mission.periodUnit,
      show: false,
      drones: [],
      missions: [],
      select: {
        drone: null,
        mission: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
    }),
  },
  mounted() {
    let _this = this;
    DroneApi.getAll(this.group.id).then((drone) => {
      _this.drones = drone.data.drones;  
    });

    if (!this.preSelectMission) {
      MissionApi.getAll(this.group.id).then((mission) => {
        this.missions = mission.data.missions;  
      });
    }
    this.restoreLast();
  },
  props: {
    preSelectMission: {
      required: false,
      type: Object,
      default: null,
    },
    customAction: {
      required: false,
      type: Boolean,
      default: false,
    },
    requireDrone: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    restoreLast() {
      let lastStatus = this.$router.currentRoute.params;
      if (lastStatus.assign) {
        // restore dialog
        this.select.drone = lastStatus.droneId;
        this.select.mission = lastStatus.missionId;
        this.open();
      }
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.select.drone = null;
      this.select.mission = null;
    },
    selectMission(missionId) {
      this.select.mission = missionId;
    },
    async assignMission() {
      if (!this.select.drone && this.requireDrone) {
        this.$showFail('No Drone Select');
        return ;
      }

      if (!this.select.mission) {
        this.$showFail('No Mission Select');
        return ;
      }
      let queryParams = {
        return: this.$router.currentRoute.name,
        returnQuery: JSON.stringify(this.$router.currentRoute.query),
      };

      if (this.$router.currentRoute.name == 'eventList') {
        this.$router.push({
          name: 'assignTriggerMap',
          params: {
            mission: this.select.mission
          },
          query: queryParams,
        });
      } else {
        this.$router.push({
          name: 'assignMap',
          params: {
            drone: this.select.drone,
            mission: this.select.mission
          },
          query: queryParams
        });
      }
      
    },
    FormatMissionPeriod,
  },
  watch: {
    preSelectMission(now) {
      // console.log("pre selection change", now, old);
      this.missions = [now];
      this.select.mission = this.missions[0].id;
    },
  },
};
</script>
<style lang="scss">
.assign-mission-label {
  font-size: 16px;
}
.el-dialog {
  hr {
    color: #c5c5c5;
    background-color: #c5c5c5;
    height: 1px;
    border: 0;
    margin-left: -38px;
    margin-right: -38px;
  }
}
.mission-selection {
  max-height: 358.5px;
  overflow-x: hidden;
  overflow-y: auto;
  // overflow: auto;
  .missions {
    margin-top: 21px;
    margin-left: 8px;
    // margin-right: 40px;
    .mission {
      cursor: pointer;
      float: left;
      width: 176px;
      height: 172px;
      padding-top:12px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px transparent;
      
      position: relative;
      margin-left: 8px;
      span {
        display: block;
        margin-top: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .thumbnail {
        width: 156px;
        height: 104px;
      }
      img.select {
        display: none;
      }
    }

    .mission:first-child:last-child {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .missions:not(.preSelect) {
    .mission:hover {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
    }

    .mission.activate {
      background-color: rgba(255, 201, 0, 0.1);
      border-color: #ffc900;
      border: solid 1px #ffc900;
      img.select {
        display: block;
        position: absolute;
        top: -12px;
        right: -12px;
        
      }
    }
  }
}
.drone-selection {
  line-height: 100px;
  height: 100px;
}
</style>
