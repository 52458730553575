<template>
  <admin-fillall-layout
    ref="content"
    :fullscreen="isFullscreen"
  >
    <title-bar
      :select-item="selectItem"
      @tab-change="switchTab"
    >
      <div
        v-show="selectItem == 'live'"
        class="live-area-fullscreen-controll"
      >
        <button
          v-show="!isFullscreen"
          class="image-button clear-all"
          @click="cancelLiveSetting"
        />
        <label
          v-show="!isFullscreen"
          class="clearAll"
          @click="cancelLiveSetting"
        >{{ $t('page.monitor.clear') }}</label>
        <button
          v-show="!isFullscreen"
          class="image-button area-fullscreen"
          @click="fullscreenContent"
        />
        <button
          v-show="isFullscreen"
          class="image-button area-exit-fullscreen"
          @click="exitFullscreen"
        />
      </div>
      <map-title
        v-if="selectItem == 'map'"
        :map-option="titleOption.map"
        :drones="drones"
        :drone-statistics="droneStatistics"
        @update:mapOption="(option) => $store.commit('monitorState/MONITOR_SET_MAP',option)"
      />
    </title-bar>

    <live-body
      v-show="selectItem == 'live'"
      ref="liveBody"
    />
    <map-body
      v-show="selectItem == 'map'"
      :drones.sync="drones"
      :activate-drone="activateDrones"
      :statistics.sync="droneStatistics"
    />
  </admin-fillall-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  FullscreenElement, ExitFullscreen, ListenFullscreenChange, RemoveListenFullscreenChange, CheckFullscreenElement,
} from '@/utils/fullscreen.js';
import TitleBar from '@/components/Monitor/TitleBar.vue';
import LiveBody from './Live.vue';
import MapTitle from './map/Title.vue';
import MapBody from './map/Index.vue';
import LoginUserApi from '@/services/api/domain/loginUser';

const defaultTab = 'live';
export default {
  name: 'Monitor',
  components: {
    TitleBar,
    LiveBody,
    MapTitle,
    MapBody,
  },
  data() {
    return {
      isFullscreen: false,
      players: [
        { url: null, info: null },
        { url: null, info: null },
        { url: null, info: null },
        { url: null, info: null },
      ],
      drones: [],
      droneStatistics: {},
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      titleOption: 'monitorState/titleOptions',
    }),
    selectItem() {
      if (this.$route.query.tab) {
        return this.$route.query.tab;
      } if (this.titleOption.tab) {
        this.switchTab(this.titleOption.tab);
        return this.titleOption.tab;
      }
      return defaultTab;
    },
    activateDrones() {
      const { displayDrones } = this.titleOption.map;
      return (displayDrones && displayDrones[this.group.id]) ? displayDrones[this.group.id] : [];
    },
  },
  destroyed() {
    RemoveListenFullscreenChange(this.fullscreenChange);
  },
  created() {
    this.$router.push({
      query: { tab: this.titleOption.tab },
    });
  },
  mounted() {
    ListenFullscreenChange(this.fullscreenChange);
  },
  methods: {
    async cancelLiveSetting() {
      const settingResponse = await LoginUserApi.getSetting();
      let groupSetting = null;
      if (settingResponse.data == null || settingResponse.data.group_live_views == null) {
        return;
      }
      for (const group of settingResponse.data.group_live_views) {
        if (group.gid === this.group.id) {
          groupSetting = group;
          break;
        }
      }

      if (groupSetting == null || groupSetting.live_views == null) {
        return;
      }

      for (const view of groupSetting.live_views) {
        const playerIndex = view.index;
        const params = {
          index: playerIndex,
          gid: this.group.id,
        };
        LoginUserApi.deleteLiveSetting(params);
      }

      this.$refs.liveBody.$emit('clearAll');
    },
    fullscreenContent() {
      if (this.isFullscreen) {
        return;
      }
      this.isFullscreen = true;
      FullscreenElement(this.$refs.content.$el);
    },
    exitFullscreen() {
      ExitFullscreen();
      this.fullscreenChange();
    },
    fullscreenChange() {
      if (CheckFullscreenElement() == null) {
        this.isFullscreen = false;
      }
    },
    switchTab(tab) {
      // wired workaround: AssignMissionDialog.vue push to assign
      // confirm page, but still trigger this (switchTab) function
      if (this.$route.name !== 'monitorHome') {
        return;
      }
      this.$router.push({
        query: { tab },
      });
      this.$store.commit('monitorState/MONITOR_SET_TAB', tab);
    },
  },
};

</script>

<style lang="scss">
.live-area-fullscreen-controll {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
  label.clearAll{
        font-size: 12px;
    /* margin-bottom: 24px; */
    left: -11px;
    position: relative;
    top: -10px;
    color: #161616;
    cursor: pointer;
  }

  button.image-button {
    width: 48px;
    height: 30px;
  }

  button.image-button.clear-all{
    background-image: url('/asserts/icon/liveview-clear-icon.svg');
    outline:none;
  }

  button.image-button.area-fullscreen {
    background-image: url('/asserts/icon/multiview-fullscreen-Icon.svg');
  }

  button.image-button.area-fullscreen:hover {
    background-image: url('/asserts/icon/multiview-fullscreen-Icon-focus.svg');
  }

  button.image-button.area-exit-fullscreen {
    background-image: url('/asserts/icon/multiview-fullscreen-close-icon.svg');
  }

  button.image-button.area-exit-fullscreen:hover {
    background-image: url('/asserts/icon/multiview-fullscreen-close-icon-focus.svg');
  }
}
</style>
