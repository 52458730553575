<template>
  <div>
    <multiple-point source="mission"
                    :value="planPoints"
                    :read-only="!editMode"
                    :z-index="zIndex + 2"
                    @before-input="$emit('before-input')"
                    @input="pointInput"
    ></multiple-point>

    <multiple-point v-if="editMode"
                    source="mission"
                    type="middlePoint"
                    :read-only="true"
                    :value="middlePoints"
                    :z-index="zIndex + 1"
    ></multiple-point>

    <vl-layer-vector :z-index="zIndex"
                     :ident="`Mission-${id}-Plan-Path`"
    >
      <!-- use geom-line-string will make line over points -->
      <!-- <vl-feature id="line-string"
                  :properties="{ type: 'PlanPath' }"
      >
        <vl-geom-line-string :coordinates="planPath" :z-index="zIndex"></vl-geom-line-string>
        <vl-style-func :factory="planPathStyle" />
      </vl-feature> -->

      <vl-source-vector :features="pathGeoJSON"
                        ref="planPathSource"
      ></vl-source-vector>
      <vl-style-func :factory="planPathStyle" />
    </vl-layer-vector>

  </div>
</template>

<script>
import { GeographicApiToOl, GeographicOlToApi, FormatGoogleDashToOl, FormatGoogleLineToOl } from '@/utils/map.js';
import { GoogleMapsMarkerToOl } from '@/utils/map.js';
import { WaypointNormalStyle, WaypointStyle, WaypointSelectedStyle, WaypointFinishedStyle, WaypointNextStyle } from '../GoogleMaps/MarkerStyle.js'
import PolylineStyle from '../GoogleMaps/PolylineStyle.js';
import MultiplePoint from "@/components/OpenLayers/MultiplePoint.vue";
import { Stroke, Fill, Style } from 'ol/style.js';

export default {
  name: 'FlyRoute',
  components: {
    MultiplePoint,
  },
  props: {
    id: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    task: {
      type: Array,
      twoWay: true,
      noBind: true,
    },
    editMode: {
      type: Boolean,
      default: true,
      noBind: true,
    },
    history: {
      type: Array,
      default() { return []; },
    },
    useNormalIcon: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: Number,
      default: 100,
      noBind: true,
    },
  },
  computed: {
    planPoints() {
      let list = this.task.map((task) => {
        return {
          icon: this.selectIconSet(task)[task.command],
          coordinate: [task.y, task.x],
        };
      });
      return {
        id: this.id,
        name: this.name,
        list,
      };
    },
    planProgress() {
      let finishedPosition = this.task.findIndex((waypoint) => !waypoint.finished);
      if (finishedPosition > 0) {
        finishedPosition -= 1;
      }
      return finishedPosition;
    },
    fullPath() {
      return this.planPoints.list.map(p => p.coordinate);
    },
    planPath() {
      return this.fullPath.slice(this.planProgress);
    },
    donePath() {
      if (this.planProgress === -1) {
        return this.fullPath;
      }
      return this.fullPath.slice(0, this.planProgress + 1);
    },
    historyPath() {
      return this.history.map((message) => {
        return GeographicApiToOl(message);
      });
    },
    returnHomePath() {
      let lastTaskId = this.task.length - 1;
      let lastTask = this.task[lastTaskId];
      if (lastTask == null) {
        return [];
      }

      if (lastTask.command === 'rtl') {
        return [
          this.fullPath[lastTaskId],
          this.fullPath[0],
        ];
      } else {
        return [];
      }      
    },
    pathGeoJSON() {
      return [{
        type: "Feature",
        id: `${this.id}-PlanPath`,
        properties: {
          type: 'PlanPath'
        },
        geometry: {
          type: "LineString",
          coordinates: this.planPath,
        },
      },{
        type: "Feature",
        id: `${this.id}-RetuenHomePath`,
        properties: {
          type: 'RetuenHomePath'
        },
        geometry: {
          type: "LineString",
          coordinates: this.returnHomePath,
        },
      },{
        type: "Feature",
        id: `${this.id}-DonePath`,
        properties: {
          type: 'DonePath'
        },
        geometry: {
          type: "LineString",
          coordinates: this.donePath,
        },
      },{
        type: "Feature",
        id: `${this.id}-HistoryPath`,
        properties: {
          type: 'HistoryPath'
        },
        geometry: {
          type: "LineString",
          coordinates: this.historyPath,
        },
      }];
    },
    middlePoints() {
      let points = {
        id: this.id,
        name: this.name,
        list: [],
      };
      if (!this.editMode) {
        return points;
      }

      for (var sequence in this.task) {
        let task = this.task[sequence];
        let nextTask = this.task[parseInt(sequence) + 1];

        if (nextTask == null) {
          break;
        }

        points.list.push({
          icon: WaypointStyle.middleWaypoint,
          coordinate: [ (task.y + nextTask.y) / 2, (task.x + nextTask.x) / 2],
        })
      }

      return points;
    },
  },
  watch: {
  },
  methods: {
    pointInput(mission) {
      mission.list = mission.list.map((task, index) => {
        return {
          ...this.task[index],
          x: task.coordinate[1],
          y: task.coordinate[0],
        };
      });
      this.$emit('update:mission', mission);
    },
    selectIconSet(task) {
      if (task.activate) {
        return WaypointSelectedStyle;
      } else if (task.next) {
        return WaypointNextStyle;
      } else if (task.finished) {
        return WaypointFinishedStyle;
      } else {
        if (this.useNormalIcon) {
          return WaypointNormalStyle;
        } else {
          return WaypointStyle;
        }
      }
    },
    planPathStyle() {
      return function(feature) {
        let properties = feature.getProperties();
        let style;
        switch (properties.type) {
          case 'PlanPath':
            style = PolylineStyle.mainPolylineOptions;
            break;
          case 'RetuenHomePath':
            style = PolylineStyle.returnHomePolylineOptions;
            break;
          case 'DonePath':
            style = PolylineStyle.donePolylineOptions;
            break;
          case 'HistoryPath':
            style = PolylineStyle.historyPolylineOptions;
            break;
        }

        if (style.icons != null) {
          return FormatGoogleDashToOl(style);
        }
        return FormatGoogleLineToOl(style);
      }
    },
  },
}
</script>